import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  tryToDeleteCarrierLogo,
  tryToEditCarrier,
  tryToFetchSingleCarrier,
} from '../../../../store/brokers/admin/carriers/carriersSlice';
import EditCarrierModalContainer from '../loading/EditCarrierModalContainer';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';

interface ICreateCarrierModal {
  openCreateCarrierModal: boolean;
  carrierItemId?: string | null;
  handleCloseCarrierModal: () => void;
  onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
  name: string;
  displayName: string;
  file: string;
  description: string;
};

const CarrierModal = ({
  openCreateCarrierModal,
  carrierItemId,
  handleCloseCarrierModal,
  onSaveChanges,
}: ICreateCarrierModal) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.carriers);
  const [fileSelected, setFileSelected] = useState<any>();
  const CarrierLoading = useAppSelector(
    (state) => state.carriers?.carrierIsLoading
  );

  const onDrop = useCallback((acceptedFiles: any) => {
    setFileSelected(acceptedFiles?.[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormValues>({});

  useEffect(() => {
    setValue('name', carrierItemId ? state?.carrierDetails?.name || '' : '');
    setValue(
      'description',
      carrierItemId ? state?.carrierDetails?.description || '' : ''
    );
    setValue(
      'displayName',
      carrierItemId ? state?.carrierDetails?.displayName || '' : ''
    );
    setValue('file', carrierItemId ? state?.carrierDetails?.file || '' : '');
  }, [state?.carrierDetails]);

  useEffect(() => {
    if (carrierItemId) {
      dispatch(tryToFetchSingleCarrier(carrierItemId));
    }
  }, [carrierItemId]);

  const onSubmit = async (data: FormValues) => {
    const formData = new FormData();
    formData.append('name', data?.name);
    formData.append('displayName', data?.displayName);
    formData.append('description', data?.description);
    formData.append('file', data?.file);
    if (fileSelected) {
      formData.append('file', fileSelected);
    }
    const payload: any = { data: formData };
    try {
      if (carrierItemId) {
        payload._id = carrierItemId;
        await dispatch(tryToEditCarrier(payload)).unwrap();
      }
      onSaveChanges('success', 'Carrier successfully changed.');
    } catch (error) {
      onSaveChanges('error', `${error}`);
    }
    reset();
    setFileSelected(undefined);
    handleCloseCarrierModal();
  };

  const onCloseModal = () => {
    reset();
    setFileSelected(undefined);
    handleCloseCarrierModal();
  };

  const onDeleteCarrierlogo = async () => {
    await dispatch(tryToDeleteCarrierLogo(carrierItemId)).unwrap();
    onSaveChanges('success', 'Carrier logo successfully deleted.');
    handleCloseCarrierModal();
  };

  return (
    <Modal open={openCreateCarrierModal} onClose={onCloseModal}>
      <div className={'p-2 min-w-[500px]'}>
        <p className="mt-2 mb-4">Edit Carrier</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          {(carrierItemId && !CarrierLoading) || !carrierItemId ? (
            <div className="flex flex-col my-4">
              <Input
                placeholder="Name"
                label={carrierItemId ? 'Name' : ''}
                register={register('name', {
                  required: {
                    message: 'Name is required',
                    value: true,
                  },
                  validate: (value: string) =>
                    !!value.trim() || 'Name is required',
                })}
                error={errors.name?.message}
              />
              <Input
                placeholder="Display Name"
                label={carrierItemId ? 'Display Name' : ''}
                register={register('displayName', {
                  required: {
                    message: 'Display name is required',
                    value: true,
                  },
                  validate: (value: string) =>
                    !!value.trim() || 'Display name is required',
                })}
                error={errors.displayName?.message}
              />
              <Input
                placeholder="Description"
                label={carrierItemId ? 'Description' : ''}
                register={register('description')}
              />
              <p className="mb-3 mt-4 font-medium">Import market flag:</p>
              <div className="mb-6">
                <div className="flex flex-col items-start" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button
                    icon={
                      <img
                        src="/assets/shared/upload-file.svg"
                        className="w-[30px] object-contains pr-2"
                      />
                    }
                    className={
                      'btn-primary-rounded-less !py-1 !shadow-none flex flex-row items-center'
                    }
                    label={'Select Image'}
                  />
                  {fileSelected && fileSelected?.name ? (
                    <p className={'ml-3'}>{fileSelected?.name}</p>
                  ) : (
                    <img
                      src={
                        state?.carrierDetails?.file ||
                        state.carrierDetails?.logo
                      }
                      className={'w-[40px] my-3'}
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-row justify-end mt-4 gap-2 ">
                <Button
                  label={'Delete Logo'}
                  className={'btn-filters'}
                  onClickButton={() => onDeleteCarrierlogo()}
                />
                <Button
                  label="Save"
                  type={'submit'}
                  className={'btn-primary'}
                />
              </div>
            </div>
          ) : (
            <EditCarrierModalContainer />
          )}
        </form>
      </div>
    </Modal>
  );
};
export default CarrierModal;
