import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAssetsTemplatesListState } from '../../../../interfaces/asset-templates/IAssetsTemplatesList';
import { AssetsTemplatesService } from './assetsTemplatesApi';


const initialState: IAssetsTemplatesListState = {
    assetsTemplatesAreLoading: false,
    assetTemplateIsLoading: false,
    assetTemplateFieldsAreLoading: false,
    assetTemplateFieldIsLoading: false,
    allAssetsAreLoading: false,
    assetTemplateFiltersAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchAllAssetsTemplates = createAsyncThunk<any>(
    'assetTemplates/tryToFetchAllAssetsTemplates',
    async () => {
        const result = await AssetsTemplatesService.tryToFetchAllAssetsTemplates();
        return result?.data?.data;
    },
);

export const assetTemplatesSlice = createSlice({
    name: 'assetTemplates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchAllAssetsTemplates
            .addCase(tryToFetchAllAssetsTemplates.pending, (state) => {
                state.allAssetsAreLoading = true;
            })
            .addCase(tryToFetchAllAssetsTemplates.rejected, (state) => {
                state.allAssetsAreLoading = false;
            })
            .addCase(tryToFetchAllAssetsTemplates.fulfilled, (state, action) => {
                state.allAssetsAreLoading = false;
                state.allAssetTemplates = action?.payload;
            })

           
    }
});

export default assetTemplatesSlice.reducer;