import React from 'react';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';

interface IEmailStep {
    onGoToNext: () => void;
    onChangeInput: (value: string | null | undefined, type: string) => void;
    registerEmail: string | undefined;
    error?: string;
    onGoToLogin: () => void;
    step: number;
}

const EmailStep = ({
    onGoToNext,
    onChangeInput,
    registerEmail,
    error,
    onGoToLogin,
    step
}: IEmailStep) => {
    return (
        <div className={`${step === 1 ? '' : 'hidden'} min-h-full flex flex-col justify-between py-4 px-3`}>
            <p className='heading-one'>Logistic Dashboard</p>
            <div className='my-2 w-full'>
                <p className='font-bold mb-2 text-base'>Register as a broker!</p>
                <Input
                    placeholder='Enter email address'
                    containerStyle='min-w-full'
                    onChangeInput={onChangeInput}
                    inputUniqueName={'registerEmail'}
                    showValue={true}
                    inputValue={registerEmail}
                    error={error}
                />
                <p className='my-2'>
                    By proceeding, you agree to our <span className='text-[#F7931E]'>Terms & Conditions</span>.
                    To find out what personal data we collect and how we use it, please visit our <span className='text-[#F7931E]'>Privacy Policy</span>.
                </p>
                <div className='flex flex-row items-center justify-end mt-6'>
                    <Button
                        className='btn-primary-text'
                        label='Already have an account?'
                        onClickButton={onGoToLogin}
                    />
                    <Button
                        className='btn-primary-rounded-less'
                        label='Next'
                        onClickButton={onGoToNext}
                    />
                </div>
            </div>
        </div>
    )
}

export default EmailStep;