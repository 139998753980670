import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddCarrierField,
    tryToEditCarrierField,
    tryToFetchCarrierField
} from '../../../../store/brokers/admin/carriers/carriersSlice';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Switch from '../../../../shared/switch';
import EditCarrierFieldContainer from '../loading/EditCarrierFieldContainer';


interface ICarrierFieldModal {
    openFieldsModal: boolean;
    fieldItemId?: string | null;
    carrierId: string;
    handleCloseFieldsModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    name: string;
    required?: boolean;
};

const CarrierFieldModal = ({
    openFieldsModal,
    fieldItemId,
    carrierId,
    handleCloseFieldsModal,
    onSaveChanges,
}: ICarrierFieldModal) => {
    const dispatch = useAppDispatch();
    const [requiredField, setRequiredField] = useState<boolean>(false)
    const state = useAppSelector((state) => state.carriers);
    const fieldLoading = useAppSelector((state) => state.carriers?.fieldIsLoading);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        setValue('name', fieldItemId ? state?.carrierField?.name || '' : '')
        setValue('required', fieldItemId ? state?.carrierField?.required || false : false)
        setRequiredField(state?.carrierField?.required || false)
    }, [state.carrierField, fieldItemId]);

    useEffect(() => {
        if (fieldItemId) {
            const data = {
                carrierId: carrierId,
                fieldId: fieldItemId
            }
            dispatch(tryToFetchCarrierField(data));
        }
    }, [fieldItemId])

    const onSubmit = async (data: FormValues) => {
        const formatData: any = {
            name: data?.name,
            required: requiredField || false,
        }
        const payload: any = {
            data: formatData,
            carrierId: carrierId
        };
        try {
            handleCloseFieldsModal()
            if (fieldItemId) {
                payload.fieldId = fieldItemId
                await dispatch(tryToEditCarrierField(payload)).unwrap();
            } else {
                await dispatch(tryToAddCarrierField(payload)).unwrap();
            }
            onSaveChanges('success', `Carrier Field successfully ${fieldItemId ? 'changed' : 'added'}.`)
        } catch (error) {
            onSaveChanges('error', `${error}`)
        }
        setRequiredField(false)
        reset();
    };

    const onCloseModal = () => {
        reset()
        setRequiredField(false)
        handleCloseFieldsModal()
    }

    const onChangeFieldRequired = () => {
        setRequiredField(!requiredField)
    }

    return (
        <Modal
            open={openFieldsModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Carrier Field</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {((!fieldLoading && fieldItemId) || !fieldItemId) ?
                        <div className='flex flex-col my-4'>
                            <Input
                                placeholder='Name'
                                register={register('name', {
                                    required: {
                                        message: 'Name is required',
                                        value: true,
                                    },
                                    validate: (value: string) => !!value.trim() || 'Name is required'
                                })}
                                error={errors.name?.message}
                            />
                            <div className={'my-3'}>
                                <Switch
                                    onToggleSwitch={onChangeFieldRequired}
                                    checked={requiredField}
                                    beforeText={'Required Field'}
                                />
                            </div>
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    type={'submit'}
                                    className={'btn-primary'}
                                />
                            </div>
                        </div>
                        : <EditCarrierFieldContainer/>
                    }
                </form>
            </div>
        </Modal>
    )
}
export default CarrierFieldModal;