import React from 'react';

const Profile = () => {
    return (
        <div>
            <div className='mb-5'>
                <p className='page-title'>Profile</p>
            </div>
        </div>
    )
}

export default Profile;