import React from 'react';
import Input from '../../../../shared/input';
import Button from '../../../../shared/button';


interface IForgotPasswordForm {
    register: any;
    errors: any;
    error?: string;
    handleSubmit: any;
    onSubmit: (data: { username: string }) => void;
}

const ForgotPasswordForm = ({
    register,
    errors,
    handleSubmit,
    onSubmit
}: IForgotPasswordForm) => {
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <p className='mb-1 font-bold text-xl'>Forgot Password</p>
                <p className='text-md mb-6'>We will Email you a Code to Reset your Password.</p>
                <Input
                    label='Enter Username'
                    inputStyle='!bg-gray-50'
                    register={register('username', {
                        required: {
                            message: 'Username is required',
                            value: true,
                        },
                        validate: (value: string) => !!value.trim() || 'Username is required'
                    })}
                    error={errors.username?.message}
                />
                <div className='flex flex-row !min-w-full justify-center my-3'>
                    <Button
                        label={'Send Code'}
                        className={'btn-primary-rounded-less my-3 !shadow-none min-w-[150px]'}
                        type='submit'
                    />
                </div>
            </form>
        </div>
    )
}
export default ForgotPasswordForm;