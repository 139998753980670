import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { authApi } from '../store/auth/authApi';
import assetTemplatesSlice from '../store/brokers/admin/asset-template/assetsTemplatesSlice';
import carriersSlice from '../store/brokers/admin/carriers/carriersSlice';
import carriersServicesSlice from '../store/brokers/admin/carriers/carriersServiceSlice';
import { userAPI } from '../store/user/userApi';
import { userDomAPI } from '../store/user/userDomApi';
import partnersSlice from '../store/brokers/admin/partners/partnersSlice';
import shippingLabelsSlice from '../store/brokers/admin/shipping-labels/shippingLabelsSlice';
import partnerCarriersSlice from '../store/brokers/partner/partner-carriers/partnerCarriersSlice';
import labelPacksSlice from '../store/brokers/admin/label-packs/labelPacksSlice';

export const store = configureStore({
  reducer: {
    [userAPI.reducerPath]: userAPI.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userDomAPI.reducerPath]: userDomAPI.reducer,
    carriers: carriersSlice,
    carrierServices: carriersServicesSlice,
    assetsTemplates: assetTemplatesSlice,
    partners: partnersSlice,
    shippingLabels: shippingLabelsSlice,
    partnerCarriers: partnerCarriersSlice,
    labelPacks: labelPacksSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userAPI.middleware,
      authApi.middleware,
      userDomAPI.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
