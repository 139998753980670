import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


const CarrierItemContainer = () => {
    return (
        <WhiteContainer
            type='rounded'
            containerStyle='min-h-[250px] min-w-[100%]'>
            <div className='flex flex-col justify-between items-center min-h-[100%]'>
                <div className='mb-4 animate-pulse'>
                    <div className='h-4 bg-gray-200 rounded dark:bg-gray-200 w-[100px] mb-1'></div>
                </div>
                <div className='mt-3 mb-6 min-h-20 w-full animate-pulse'>
                    <div className='h-20 bg-gray-200 rounded dark:bg-gray-200 w-full'></div>
                </div>


            </div>
        </WhiteContainer>
    )
}

export default CarrierItemContainer;