import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CarriersService } from './carriersApi';
import { PaginatedCarriers } from '../../../../interfaces/carriers/paginatedStore.type';
import { ICarriersListState } from '../../../../interfaces/carriers/ICarrierList';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { ICarrierSchema } from '../../../../interfaces/carriers/ICarrierSchema';
import { AxiosError } from 'axios';
import { ICarrier } from '../../../../interfaces/carriers/ICarrier';
import { ICarrierField } from '../../../../interfaces/carriers/ICarrierField';


const initialState: ICarriersListState = {
    carriersAreLoading: false,
    toastSuccessRequest: false,
    carrierIsLoading: false,
    fieldIsLoading: false,
    requestStatus: 'default',
    message: '',
};

// Paginated
export const tryToFetchCarriers = createAsyncThunk<
    PaginatedCarriers,
    IPaginationPayload
>('carriers/tryToFetchCarriers', async ({ pageNumber, pageSize, filters }) => {
    const result = await CarriersService.tryToFetchCarriers(
        pageNumber,
        pageSize,
        filters,
    );
    return result?.data;
});

export const tryToFetchSingleCarrier = createAsyncThunk<
    any,
    string
>('carriers/tryToFetchSingleCarrier', async (carrierId: string, { rejectWithValue }) => {
    try {
        const result = await CarriersService.tryToFetchSingleCarrier(carrierId);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToFetchAllCarriers = createAsyncThunk<any, undefined>(
    'carriers/tryToFetchAllCarriers',
    async () => {
        const result = await CarriersService.tryToFetchAllCarriers();
        return result.data;
    }
);

export const tryToEditCarrier = createAsyncThunk<
    ApiResponse<ICarrierSchema>,
    any
>('carriers/tryToEditCarrier', async (data: any, { rejectWithValue }) => {
    try {
        const result = await CarriersService.tryToEditCarrier(
            data._id || '',
            data?.data,
        );
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToAddCarrier = createAsyncThunk<
    ApiResponse<ICarrierSchema>,
    any
>('carriers/tryToAddCarrier', async (data: any, { rejectWithValue }) => {
    try {
        const result = await CarriersService.tryToAddCarrier(data);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToDisableCarrier = createAsyncThunk<
    ApiResponse<ICarrierSchema>,
    { carrierId: string }
>('stores/tryToDisableCarrier', async (data, { rejectWithValue }) => {
    try {
        const results = await CarriersService.tryToDisableCarrier(data?.carrierId);
        return results.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToDeleteCarrier = createAsyncThunk<ApiResponse<ICarrierSchema>, { carrierId: string }>(
    'stores/tryToDeleteCarrier',
    async (data, { rejectWithValue }) => {
        try {
            const results = await CarriersService.tryToDeleteCarrier(data?.carrierId);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToEditCarrierField = createAsyncThunk<
    ICarrier,
    any
>('carriers/tryToEditCarrierField', async ({ carrierId, fieldId, data }, { rejectWithValue }) => {
    try {
        const result = await CarriersService.tryToEditCarrierField(carrierId, fieldId, data);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToFetchCarrierField = createAsyncThunk<
    any,
    { carrierId: string, fieldId: string }
>('carriers/tryToFetchCarrierField', async ({ carrierId, fieldId }, { rejectWithValue }) => {
    try {
        const result = await CarriersService.tryToFetchCarrierField(carrierId, fieldId);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToAddCarrierField = createAsyncThunk<
    ICarrierSchema,
    { carrierId: string, data: any }
>('carriers/tryToAddCarrierField', async ({ carrierId, data }, { rejectWithValue }) => {
    try {
        const result = await CarriersService.tryToAddCarrierField(carrierId, data);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToAddCarrierLogo = createAsyncThunk<
    ICarrierSchema,
    { id: string, data: any }
>('carriers/tryToAddCarrierLogo', async ({ id, data }, { rejectWithValue }) => {
    try {
        const result = await CarriersService.tryToAddCarrierLogo(id, data);
        return result.data
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
})

export const tryToDeleteCarrierField = createAsyncThunk<ApiResponse<ICarrierField>, any>(
    'carriers/tryToDeleteCarrierField',
    async ({ carrierId, fieldId }, { rejectWithValue }) => {
        try {
            const results: any = await CarriersService.tryToDeleteCarrierField(carrierId, fieldId);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeleteCarrierLogo = createAsyncThunk<ApiResponse<ICarrier>, any>(
    'carriers/tryToDeleteCarrierLogo',
    async (id, { rejectWithValue }) => {
        try {
            const result: any = await CarriersService.tryToDeleteCarrierLogo(id);
            return result.data
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message)
        }
    }
)

export const carriersSlice = createSlice({
    name: 'carriers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchCarriers
            .addCase(tryToFetchCarriers.pending, (state) => {
                state.carriersAreLoading = true;
            })
            .addCase(tryToFetchCarriers.rejected, (state) => {
                state.carriersAreLoading = false;
            })
            .addCase(tryToFetchCarriers.fulfilled, (state, action) => {
                state.carriersAreLoading = false;
                state.carriers = action.payload;
            })

            // tryToFetchSingleCarrier
            .addCase(tryToFetchSingleCarrier.pending, (state) => {
                state.carrierIsLoading = true;
            })
            .addCase(tryToFetchSingleCarrier.rejected, (state) => {
                state.carrierIsLoading = false;
            })
            .addCase(tryToFetchSingleCarrier.fulfilled, (state, action) => {
                state.carrierIsLoading = false;
                state.carrierDetails = action.payload && action.payload?.[0];
            })

            // tryToAddCarrier
            .addCase(tryToAddCarrier.pending, (state) => {
                state.carrierIsLoading = true;
            })
            .addCase(tryToAddCarrier.rejected, (state) => {
                state.carrierIsLoading = false;
            })
            .addCase(tryToAddCarrier.fulfilled, (state, action) => {
                state.carrierIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.carriers?.data)
                    state.carriers.data.elements = [
                        ...state.carriers?.data.elements,
                        data,
                    ];
            })

            // tryToDisableCarrier
            .addCase(tryToDisableCarrier.pending, (state) => {
                state.carrierIsLoading = true;
            })
            .addCase(tryToDisableCarrier.rejected, (state) => {
                state.carrierIsLoading = false;
            })
            .addCase(tryToDisableCarrier.fulfilled, (state, action) => {
                state.carrierIsLoading = false;
                state.requestStatus = 'success';
                if (state.carriers?.data.elements)
                    state.carriers.data.elements =
                        { ...state }.carriers?.data.elements.filter(
                            (carrier) =>
                                carrier?._id !== action.meta.arg?.carrierId,
                        ) || [];
            })

             // tryToDeleteCarrier
             .addCase(tryToDeleteCarrier.pending, (state) => {
                state.carrierIsLoading = true;
            })
            .addCase(tryToDeleteCarrier.rejected, (state) => {
                state.carrierIsLoading = false;
            })
            .addCase(tryToDeleteCarrier.fulfilled, (state, action) => {
                state.carrierIsLoading = false;
                state.requestStatus = 'success';
                if (state.carriers?.data.elements)
                    state.carriers.data.elements =
                        { ...state }.carriers?.data.elements.filter(
                            (carrier) =>
                                carrier?._id !== action.meta.arg?.carrierId,
                        ) || [];
            })

            // tryToEditCarrier
            .addCase(tryToEditCarrier.pending, (state) => {
                state.carrierIsLoading = true;
            })
            .addCase(tryToEditCarrier.rejected, (state) => {
                state.carrierIsLoading = false;
            })
            .addCase(tryToEditCarrier.fulfilled, (state, action) => {
                state.carrierIsLoading = false;
                if (state.carriers?.data.elements)
                    state.carriers.data.elements =
                        { ...state }.carriers?.data.elements.map((carrier) => {
                            if (carrier?._id === action.meta?.arg?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return carrier;
                            }
                        }) || [];
                state.carrierDetails = action.payload.data;
            })

            // tryToFetchCarrierField
            .addCase(tryToFetchCarrierField.pending, (state) => {
                state.fieldIsLoading = true;
            })
            .addCase(tryToFetchCarrierField.rejected, (state) => {
                state.fieldIsLoading = false;
            })
            .addCase(tryToFetchCarrierField.fulfilled, (state, action) => {
                state.fieldIsLoading = false;
                state.carrierField = action.payload;
            })

            // tryToAddCarrierField
            .addCase(tryToAddCarrierField.pending, (state) => {
                state.fieldIsLoading = true;
            })
            .addCase(tryToAddCarrierField.rejected, (state) => {
                state.fieldIsLoading = false;
            })
            .addCase(tryToAddCarrierField.fulfilled, (state, action) => {
                state.fieldIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload || action.meta.arg;
                if (state.carrierDetails) state.carrierDetails = data;
            })

            // tryToEditCarrierLogo
            .addCase(tryToAddCarrierLogo.pending, (state) => {
                state.fieldIsLoading = true;
            })
            .addCase(tryToAddCarrierLogo.rejected, (state) => {
                state.fieldIsLoading = false;
            })
            .addCase(tryToAddCarrierLogo.fulfilled, (state, action) => {
                state.fieldIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload || action.meta.arg;
                if (state.carrierDetails) state.carrierDetails = data;
            })

            // tryToEditCarrierField
            .addCase(tryToEditCarrierField.pending, (state) => {
                state.fieldIsLoading = true;
            })
            .addCase(tryToEditCarrierField.rejected, (state) => {
                state.fieldIsLoading = false;
            })
            .addCase(tryToEditCarrierField.fulfilled, (state, action) => {
                state.fieldIsLoading = false;
                if (state.carrierDetails) {
                    state.carrierDetails = action.payload
                }
            })

            // tryToDeleteCarrierField
            .addCase(tryToDeleteCarrierField.pending, (state) => {
                state.fieldIsLoading = true;
            })
            .addCase(tryToDeleteCarrierField.rejected, (state) => {
                state.fieldIsLoading = false;
            })
            .addCase(tryToDeleteCarrierField.fulfilled, (state, action) => {
                state.fieldIsLoading = false;
                state.requestStatus = 'success';
                if (state.carrierDetails)
                    state.carrierDetails.fields =
                        { ...state }.carrierDetails?.fields?.filter((field) => field?.id !== action?.meta?.arg?.fieldId) || [];
            })

            // tryToDeleteCarrierLogo
            .addCase(tryToDeleteCarrierLogo.pending, (state) => {
                state.fieldIsLoading = true;
            })
            .addCase(tryToDeleteCarrierLogo.rejected, (state) => {
                state.fieldIsLoading = false;
            })
            .addCase(tryToDeleteCarrierLogo.fulfilled, (state, action) => {
                state.fieldIsLoading = false;
                state.requestStatus = 'success';
                if (state.carrierDetails)
                    state.carrierDetails.logo = ''
            })
    },
});

export default carriersSlice.reducer;
