import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import { IPartnersListState } from '../../../../interfaces/partners/IPartnersList';
import { IPartnersSchema } from '../../../../interfaces/partners/IPartnersSchema';
import { PartnersService } from './partnersApi';

const initialState: IPartnersListState = {
    partnerIsLoading: false,
    partnersAreLoading: false,
    partnerMarketsAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToAddPartner = createAsyncThunk<ApiResponse<IPartnersSchema>, IPartners>(
    'partners/tryToAddPartner',
    async (data: IPartners, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToAddPartner(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToChangeActiveMarket = createAsyncThunk<
    any,
    { marketId: string }
>('stores/tryToChangeActiveMarket', async (data) => {
    return data?.marketId;
});

export const tryToFindOperatingMarkets = createAsyncThunk<Array<any>, null>(
    'agents/tryToFindOperatingMarkets',
    async () => {
        const result = await PartnersService.tryToFindOperatingMarkets();
        return result?.data;
    },
);

export const partnersSlice = createSlice({
    name: 'partners',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToAddPartner
            .addCase(tryToAddPartner.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToAddPartner.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToAddPartner.fulfilled, (state, action) => {
                state.partnerIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.partners?.data) state.partners.data.elements = [...state.partners?.data.elements, data];
            })

            // tryToFindOperatingMarkets
            .addCase(tryToFindOperatingMarkets.pending, (state) => {
                state.partnerMarketsAreLoading = true;
            })
            .addCase(tryToFindOperatingMarkets.rejected, (state) => {
                state.partnerMarketsAreLoading = false;
            })
            .addCase(tryToFindOperatingMarkets.fulfilled, (state, action) => {
                state.partnerMarketsAreLoading = false;
                state.partnerMarkets = action.payload;
                const getActiveMarket = localStorage.getItem('active-market')
                const activeMarket = getActiveMarket && getActiveMarket !== 'undefined' && JSON.parse(getActiveMarket)
                const findActiveMarket = action.payload && activeMarket && action.payload?.find((market) => market?.market?._id === activeMarket?.market?._id)
                state.partnerActiveMarket = findActiveMarket || action?.payload?.[0]
                if (!activeMarket) {
                    localStorage.setItem('active-market', JSON.stringify(action?.payload?.[0]))
                }
            })

            // tryToChangeActiveMarket
            .addCase(tryToChangeActiveMarket.pending, (state) => {
                state.partnerMarketsAreLoading = true;
            })
            .addCase(tryToChangeActiveMarket.rejected, (state) => {
                state.partnerMarketsAreLoading = false;
            })
            .addCase(tryToChangeActiveMarket.fulfilled, (state, action) => {
                state.partnerMarketsAreLoading = false;
                const findActiveMarket = state?.partnerMarkets && state?.partnerMarkets?.length > 0 && state?.partnerMarkets?.find((market: IActiveMarket) => market?.market?._id === action?.meta?.arg?.marketId)
                state.partnerActiveMarket = findActiveMarket || undefined;
                localStorage.setItem('active-market', JSON.stringify(findActiveMarket))
            })
    }
});
export default partnersSlice.reducer;