import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { ICarrier } from '../../../interfaces/carriers/ICarrier';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import {
    tryToDeleteCarrier,
    tryToFetchCarriers,
} from '../../../store/brokers/admin/carriers/carriersSlice';
import CarriersTable from './table/CarriersTable';
import CarrierModal from '../carriers/modals/CarrierModal';
import Toast from '../../../shared/toast';
import Button from '../../../shared/button';
import DeleteModal from '../../../shared/delete-modal';
import EmptyContainer from '../../../shared/empty-container';


const Carriers = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<ICarrier[]>([]);
    const [showToast, setShowToast] = useState<IToast | null>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [carrierItem, setCarrierItem] = useState<string>();
    const [carrierItemId, setCarrierItemId] = useState<string | null>();
    const [showCarrierItemModal, setShowCarrierItemModal] = useState<boolean>(false);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const paginationData = useAppSelector((state) => { return state?.carriers.carriers?.data?.page });
    const rowsLoading = useAppSelector((state) => { return state?.carriers.carriersAreLoading; });
    const state = useAppSelector((state) => state?.carriers);

    useEffect(() => {
        if (state?.carriers) {
            const rows =
                state?.carriers?.data?.elements &&
                    Array.isArray(state?.carriers?.data.elements)
                    ? state?.carriers?.data.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state?.carriers]);

    useEffect(() => {
        dispatch(tryToFetchCarriers(paginationState));
    }, [paginationState]);

    const onAddNewCarrier = () => {
        navigate('/carriers/create');
    };

    const onSeeCarrierDetails = (id: string) => {
        navigate(`/carriers/details/${id}`);
    };

    const onSeeCarrierServices = (id: string) => {
        navigate(`/carriers/services/${id}`);
    };

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const onDeleteCarrier = async () => {
        try {
            const data = {
                carrierId: carrierItem || '',
            };
            await dispatch(tryToDeleteCarrier(data));
            setShowToast({
                type: 'success',
                message: 'Carrier successfully deleted',
            });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setCarrierItem(undefined);
        setShowDeleteModal(false);
    };

    const onAddCarrier = (id?: string) => {
        if (id) {
            setCarrierItemId(id);
        }
        setShowCarrierItemModal(true);
    };

    const onCloseCarrierModal = () => {
        setCarrierItemId(null);
        setShowCarrierItemModal(false);
    };

    const onSaveCarrierChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        dispatch(tryToFetchCarriers(paginationState));
    };

    const onDeleteCarrierRequest = (id: string) => {
        setShowDeleteModal(true);
        setCarrierItem(id);
    };

    useEffect(() => {
        dispatch(tryToFetchCarriers(paginationState));
    }, [paginationState]);

    return (
        <div>
            <div className="mb-5">
                <p className="page-title">Carriers</p>
            </div>
            <div className="flex flex-row justify-end">
                <Button
                    label={'Add New Carrier'}
                    className={'btn-main'}
                    onClickButton={onAddNewCarrier}
                />
            </div>
            <div className="my-5 relativ">
                {rows?.length > 0 || rowsLoading ? (
                    <CarriersTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        onSeeCarrierDetails={onSeeCarrierDetails}
                        onSeeCarrierServices={onSeeCarrierServices}
                        setPaginationState={setPaginationState}
                        onDeleteCarrierRequest={onDeleteCarrierRequest}
                        onEditCarrierField={onAddCarrier}
                    />
                ) : (
                    <EmptyContainer text={'No carrier added yet.'} showImage={true} />
                )}
            </div>
            <CarrierModal
                openCreateCarrierModal={showCarrierItemModal}
                handleCloseCarrierModal={onCloseCarrierModal}
                onSaveChanges={onSaveCarrierChanges}
                carrierItemId={carrierItemId}
            />
            <DeleteModal
                openDeleteModal={showDeleteModal}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onDeleteCarrier}
            />
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
        </div>
    );
};

export default Carriers;
