import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import {
    tryToChangeLabelPackStatus,
    tryToFetchLabelPacksFiltered
} from '../../../../store/brokers/admin/label-packs/labelPacksSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface ILabelsActionModal {
    openLabelsActionModal: boolean;
    errorType?: boolean;
    text: string;
    buttonText: string;
    title: string;
    loading?: boolean;
    handleCloseModal: () => void;
    onApproveAction: () => void;
}

const LabelsActionModal = ({
    openLabelsActionModal,
    errorType,
    text,
    buttonText,
    title,
    loading,
    handleCloseModal,
    onApproveAction,
}: ILabelsActionModal) => {
    const dispatch = useAppDispatch()
    const [selectedCategory, setSelectedCategory] = useState<any>([])
    const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const categories = useAppSelector((state) => state.assetsTemplates?.allAssetTemplates);
    const allLabelPacksNotPaginated = useAppSelector(state => state.labelPacks.labelPacksNotPaginated)

    const getLabelPacksByCategoryAndFilters = async () => {
        const data = {
            category: selectedCategory.name,
            status: 'created'
        }
        await dispatch(tryToFetchLabelPacksFiltered({ data })).unwrap()
    }

    const onSelectCategory = (category: any) => {
        setSelectedCategory(category);
    };

    const onClickCompleteAll = async () => {
        if (loadingComplete) {
            return
        }
        try {
            const data = {
                newStatus: 'completed',
                labelIds: { labelIds: allLabelPacksNotPaginated?.map(label => label._id) }
            }
            await dispatch(tryToChangeLabelPackStatus(data)).unwrap()
            setLoadingComplete(true)
            getLabelPacksByCategoryAndFilters();
            setError(undefined)
        } catch (err) {
            setError(`${err}`)
        }
        setLoadingComplete(false)
        onApproveAction()
    }

    useEffect(() => {
        getLabelPacksByCategoryAndFilters();
    }, [selectedCategory])

    return (
        <Modal
            open={openLabelsActionModal}
            onClose={() => handleCloseModal()}>
            <div className={'grid gap-2 p-2  min-w-[500px]'}>
                <p className='font-medium text-xl text-slate-900'>{title}</p>
                <p className='my-2'>{text}</p>
                {error && <Error text={error}/>}
                <SelectCheckbox
                    name={''}
                    placeholder={'Category'}
                    options={categories?.map((category: IAssetTemplate) => {
                        const categoryWithLabelAndValue =
                        {
                            ...category,
                            label: category.displayName,
                            value: category.name
                        }
                        return categoryWithLabelAndValue
                    })}
                    selectedOption={selectedCategory}
                    onChangeSelectedOption={onSelectCategory}
                    dynamicOptions={true}
                    containerStyle='min-h-[150px]'
                />
                <div className='flex flex-row justify-end my-2'>
                    <Button
                        label={buttonText}
                        className={errorType ? 'btn-error' : loading ? 'btn-main-disable' : 'min-w-[120px] btn-main !py-1 px-3 !shadow-none'}
                        onClickButton={() => onClickCompleteAll()}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default LabelsActionModal;