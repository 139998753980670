import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { AdminGuard } from './guards/RolesGuard';
import { UserGuard } from './guards/UserGuard';
import { routes } from './router/routes';
import RouteWrapper from './router/RouteWrapper';


function App() {
    const redirectNotExistingRoutes = UserGuard() ? (AdminGuard() ? '/carriers' : '/broker-carriers') : '/login'
    
    return (
        <div className='App !font-dm_sansregular'>
            <Routes>
                {routes.map((route, index) =>
                    route.childrens ? (
                        <React.Fragment key={index}>
                            {route.index && <Route
                                path={route.path}
                                element={<Navigate to={route.index} replace />}
                            />}
                            <Route path={route.path} element={<RouteWrapper guard={route.guard} element={route.element} />}>
                                {route.childrens.map((r, i: number) => (
                                    <Route key={i} path={r.path} element={<RouteWrapper guard={r.guard || route.guard} element={r.element} />} />
                                ))}
                            </Route>
                        </React.Fragment>

                    ) : (
                        <Route
                            key={index}
                            path={route.path}
                            element={<RouteWrapper guard={route.guard} element={route.element} />}
                        />
                    ),
                )}
                <Route path='*' element={<Navigate replace to={redirectNotExistingRoutes || '/login'} />} />
            </Routes>
        </div>
    );
}

// export default App;
export default Sentry.withProfiler(App);