import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


const EditCarrierModalContainer = () => {
    return (
        <WhiteContainer>
            <div>
                <div className='mt-3 mb-10 animate-pulse'>
                    <div className='h-3 bg-gray-200 rounded dark:bg-gray-200 w-[150px] mb-2'></div>
                    <div className='h-7 bg-gray-200 rounded dark:bg-gray-200 min-w-[100%] mb-1'></div>
                </div>
                <div className='mt-3 mb-10 animate-pulse'>
                    <div className='h-3 bg-gray-200 rounded dark:bg-gray-200 w-[150px] mb-2'></div>
                    <div className='h-7 bg-gray-200 rounded dark:bg-gray-200 min-w-[100%] mb-1'></div>
                </div>
                <p className='mb-3 mt-5 font-medium'>Import market flag:</p>
                <div className='mt-2 mb-10 animate-pulse'>
                    <div className='h-8 bg-gray-200 rounded dark:bg-gray-200 w-[150px] mb-1'></div>
                </div>
                <div className='flex flex-row justify-end mt-4 gap-2 animate-pulse'>
                    <div className='h-8 bg-gray-200 rounded dark:bg-gray-200 w-[150px] mb-1'></div>
                    <div className='h-8 bg-gray-200 rounded dark:bg-gray-200 w-[150px] mb-1'></div>
                </div>
            </div>
        </WhiteContainer>
    )
}

export default EditCarrierModalContainer;