import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CarriersService } from './carriersServiceApi';
import { ICarrierServicesListState } from '../../../../interfaces/carriers/ICarrierServiceList';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { ICarrierSchema } from '../../../../interfaces/carriers/ICarrierSchema';
import { AxiosError } from 'axios';

const initialState: ICarrierServicesListState = {
  carrierServiceIsLoading: false,
  carrierServicesAreLoading: false,
  toastSuccessRequest: false,
  requestStatus: 'default',
  message: '',
};

export const tryToFetchCarrierServices = createAsyncThunk<any, string>(
  'carriers/tryToFetchCarrierServices',
  async (carrierId: string, { rejectWithValue }) => {
    try {
      const result = await CarriersService.tryToFetchCarrierServices(carrierId);
      return result.data;
    } catch (error) {
      const err: any = error as AxiosError;
      return rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const tryToAddCarrierService = createAsyncThunk<
  ApiResponse<ICarrierSchema>,
  any
>('carriers/tryToAddCarrierService', async (data: any, { rejectWithValue }) => {
  try {
    const result = await CarriersService.tryToAddCarrierService(data, data.carrierId);
    return result.data;
  } catch (error) {
    const err: any = error as AxiosError;
    return rejectWithValue(err?.response?.data?.message);
  }
});

export const tryToEditCarrierService = createAsyncThunk<any, any>(
  'carriers/tryToEditCarrierService',
  async ({ serviceId, carrierId, name, description, statusPayload }, { rejectWithValue }) => {
    try {
      const result = await CarriersService.tryToEditCarrierService(
        carrierId,
        serviceId,
        name,
        description,
        statusPayload
      );
      return result.data;
    } catch (error) {
      const err: any = error as AxiosError;
      return rejectWithValue(err?.response?.data?.message);
    }
  }
);


export const tryToDeleteCarrierService = createAsyncThunk<
  ApiResponse<any>,
  any
>(
  'carriers/tryToDeleteCarrierService',
  async ({ carrierId, serviceId
    // , data
  }, { rejectWithValue }) => {
    try {
      const results: any = await CarriersService.tryToDeleteCarrierService(
        carrierId,
        serviceId,
        // data
      );
      return results.data;
    } catch (error) {
      const err: any = error as AxiosError;
      return rejectWithValue(err?.response?.data?.message);
    }
  }
);


export const carriersServicesSlice = createSlice({
  name: 'carrierServices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // tryToFetchCarriers
      .addCase(tryToFetchCarrierServices.pending, (state) => {
        state.carrierServicesAreLoading = true;
      })
      .addCase(tryToFetchCarrierServices.rejected, (state) => {
        state.carrierServicesAreLoading = false;
      })
      .addCase(tryToFetchCarrierServices.fulfilled, (state, action) => {
        state.carrierServicesAreLoading = false;
        state.carrierServiceDetails = action.payload[0]
      })

      //  tryToAddCarrierService
      .addCase(tryToAddCarrierService.pending, (state) => {
        state.carrierServiceIsLoading = true;
      })
      .addCase(tryToAddCarrierService.rejected, (state) => {
        state.carrierServiceIsLoading = false;
      })
      .addCase(tryToAddCarrierService.fulfilled, (state, action) => {
        state.carrierServiceIsLoading = false;
        state.requestStatus = 'success';
        const data = action.payload.data || action.meta.arg;
        if (state.carrierServices) state.carrierServices = data;

      })

      // tryToEditCarrierService
      .addCase(tryToEditCarrierService.pending, (state) => {
        state.carrierServiceIsLoading = true;
      })
      .addCase(tryToEditCarrierService.rejected, (state) => {
        state.carrierServiceIsLoading = false;
      })
      .addCase(tryToEditCarrierService.fulfilled, (state, action) => {
        state.carrierServiceIsLoading = false;
        if (state.carrierServiceDetails) {
          state.carrierServiceDetails = action.payload;
        }
      })

      // tryToDeleteCarrierService
      .addCase(tryToDeleteCarrierService.pending, (state) => {
        state.carrierServiceIsLoading = true;
      })
      .addCase(tryToDeleteCarrierService.rejected, (state) => {
        state.carrierServiceIsLoading = false;
      })
      .addCase(tryToDeleteCarrierService.fulfilled, (state, action) => {
        state.carrierServiceIsLoading = false;
        state.requestStatus = 'success';
        if (state.carrierServiceDetails)
          state.carrierServiceDetails.services =
            { ...state }.carrierServiceDetails?.services?.filter((service: any) => service?._id !== action?.meta?.arg?.serviceId) || [];
      })
  },
});

export default carriersServicesSlice.reducer;
