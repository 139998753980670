import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { NavLink, Outlet } from 'react-router-dom';
import Navigation from './layout-components/Navigation';
import Sidebar from './layout-components/Sidebar';
import Profile from './layout-components/Profile';

const Layout = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    return (
        <div className='bg-bgColor-primary'>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as='div' className='relative z-40 md:hidden' onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter='transition-opacity ease-linear duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='transition-opacity ease-linear duration-300'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
                    </Transition.Child>

                    <div className='fixed inset-0 flex z-40'>
                        <Transition.Child
                            as={Fragment}
                            enter='transition ease-in-out duration-300 transform'
                            enterFrom='-translate-x-full'
                            enterTo='translate-x-0'
                            leave='transition ease-in-out duration-300 transform'
                            leaveFrom='translate-x-0'
                            leaveTo='-translate-x-full'
                        >
                            <Dialog.Panel className='relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white'>
                                <Transition.Child
                                    as={Fragment}
                                    enter='ease-in-out duration-300'
                                    enterFrom='opacity-0'
                                    enterTo='opacity-100'
                                    leave='ease-in-out duration-300'
                                    leaveFrom='opacity-100'
                                    leaveTo='opacity-0'
                                >
                                    <div className='absolute top-0 right-0 -mr-12 pt-2'>
                                        <button
                                            type='button'
                                            className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className='sr-only'>Close sidebar</span>
                                            {/* <XIcon className='h-6 w-6 text-white' aria-hidden='true' /> */}
                                            <span className='h-6 w-6 text-white' aria-hidden='true' />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className='flex-shrink-0 flex items-center px-4'>
                                    <NavLink to='/'>
                                        {/* Logo here */}
                                        {/* <img
                                            className='h-8 w-auto'
                                            src='/logo/logo.svg'
                                            alt='Workflow'
                                        /> */}
                                    </NavLink>
                                </div>
                                <div className='mt-5 flex-1 h-0 overflow-y-auto'>
                                    <nav className='px-2 space-y-1'>
                                        <Navigation />
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className='flex-shrink-0 w-14' aria-hidden='true'>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <Sidebar />
            <div className='flex flex-col flex-1 min-h-screen'>
                <div className='sticky top-0 z-20 m-4 h-16 bg-[#f7f9fb]'>
                    <div className='fixed left-0 top-1 right-0 z-20 flex-shrink-0 flex h-16 bg-white m-4 rounded-xl'>
                        <button
                            type='button'
                            className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden'
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className='sr-only'>Open sidebar</span>
                            {/* <MenuAlt2Icon className='h-6 w-6' aria-hidden='true' /> */}
                        </button>
                        <div className='flex-1 px-4 flex justify-between'>
                            <div className='flex-1 flex'>
                                <NavLink
                                    to='/home'
                                    className='hidden md:block h-11 w-auto my-auto mx-3'
                                >
                                    {/* Logo here */}
                                    {/* <img
                                    className='h-11 w-auto'
                                    src='/logo/logo.svg'
                                    alt='Workflow'
                                /> */}
                                </NavLink>

                                <Profile />
                                <div className='flex ml-auto'>
                                    <form className='hidden w-full md:flex md:ml-0 my-auto' action='#' method='GET'>
                                        <label htmlFor='search-field' className='sr-only'>
                                            Search
                                        </label>
                                        <div className='relative w-full text-gray-400 focus-within:text-gray-600'>
                                            <div className='absolute inset-y-0 left-2 flex items-center pointer-events-none'>
                                                <img alt='search' className='h-4 w-4' src='/svg/layout/search-icon.svg' />
                                            </div>
                                            <input
                                                id='search-field'
                                                className='bg-bgColor-search block h-full pl-8 p-3 rounded-xl border-transparent placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm'
                                                placeholder='Search'
                                                type='search'
                                                name='search'
                                            />
                                        </div>
                                    </form>
                                    <button
                                        type='button'
                                        className='ml-2 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none'
                                    >
                                        <span className='sr-only'>View notifications</span>
                                        <img alt='search' className='h-6 w-6' src='/svg/layout/notification-icon.svg' />
                                        {/* <BellIcon className='h-6 w-6' aria-hidden='true' /> */}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <main className='md:pl-64 w-full h-full md:min-h-[calc(100vh-6rem)]'>
                    <div className='md:ml-4 xl:ml-20 px-5 py-4'>
                        <Outlet />
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Layout;