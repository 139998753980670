import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../app/hooks';
import { tryToAddCarrier } from '../../../store/brokers/admin/carriers/carriersSlice';
import Button from '../../../shared/button';
import Input from '../../../shared/input';
import Error from '../../../shared/error';


type FormValues = {
    name: string;
    displayName: string;
    description: string;
    file: string;
};

const CreateCarrier = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [createError, setCreateError] = useState<string | null>();
    const [fileSelected, setFileSelected] = useState<any>()

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormValues>({});

    const onSubmit = async (data: FormValues) => {
        if (!(fileSelected)) {
            setCreateError('Flag image is required.')
            return;
        }
        const formData = new FormData()
        formData.append('displayName', data?.displayName)
        formData.append('name', data?.name)
        formData.append('description', data?.description)
        formData.append('file', data?.file)
        const dataToSend = {
            name: data?.name,
            displayName: data?.displayName,
            description: data?.description,
            file: data?.file
        }
        if (fileSelected) {
            formData.append('file', fileSelected)
            dataToSend.file = fileSelected
        }
        try {
            await dispatch(tryToAddCarrier(formData)).unwrap();
            reset();
            navigate('/carriers');
            setCreateError(null);
        } catch (error) {
            setCreateError(`${error}`);
        }
    }

    return (
        <div>
            <div>
                <p className='page-title'>Create Carrier</p>
            </div>
            <div className='mt-5 mb-2'>
                {createError && <Error text={createError} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='grid grid-cols-3 gap-4 content-center'>
                        <Input
                            placeholder='Name'
                            register={register('name', {
                                required: {
                                    message: 'Name is required',
                                    value: true,
                                },
                                validate: (value: string) =>
                                    !!value.trim() || 'Name is required',
                            })}
                            error={errors.name?.message}
                        />
                        <Input
                            placeholder='Display Name'
                            register={register('displayName', {
                                required: {
                                    message: 'Display Name is required',
                                    value: true,
                                },
                                validate: (value: string) =>
                                    !!value.trim() || 'Display Name is required',
                            })}
                            error={errors.name?.message}
                        />
                        <Input
                            placeholder='Description'
                            register={register('description', {
                                required: {
                                    message: 'Description is required',
                                    value: true,
                                },
                                validate: (value: string) =>
                                    !!value.trim() || 'Description is required',
                            })}
                            error={errors.description?.message}
                        />
                    </div>
                    <div className='mb-6'>
                        <p className='mb-3 mt-4 font-medium'>Import market flag:</p>
                        <div className='flex flex-row items-center' {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button
                                icon={<img src='/assets/shared/upload-file.svg' className='w-[30px] object-contains pr-2' />}
                                className={'btn-primary-rounded-less !py-1 !shadow-none flex flex-row items-center'}
                                label={'Select Image'}
                            />
                            {fileSelected && <p className={'ml-3'}>{fileSelected?.name}</p>}
                        </div>
                    </div>
                    <div className='flex flex-row justify-start py-2'>
                        <Button
                            label='Create Carrier'
                            type={'submit'}
                            className={'btn-primary'}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
export default CreateCarrier
