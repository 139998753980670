import React from 'react';
import { LABEL_PACKS_TYPES } from '../../../../utils/constants/label-packs-types';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Input from '../../../../shared/input';

interface ILabelPacksFilters {
    onChangeUserId: (value: string | null | undefined, type: string) => void;
    onChangeUserName: (value: string | null | undefined, type: string) => void;
    onChangeItemName: (value: string | null | undefined, type: string) => void;
    onChangeSelectType: (type: any) => void;
    selectedType: any;
    filters: any;
    categories: Array<any>
    selectedCategory?: any;
    onSelectCategory: (Category: any) => void;
}

const LabelPacksFilters = ({
    onChangeUserId,
    onChangeUserName,
    onChangeItemName,
    onChangeSelectType,
    selectedType,
    filters,
    categories,
    selectedCategory,
    onSelectCategory,
}: ILabelPacksFilters) => {
    return (
        <div className='mb-2 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 sm:gap-1 md:gap-2 lg:gap-4'>
            <SelectCheckbox
                name={''}
                placeholder={'Category'}
                options={categories}
                selectedOption={selectedCategory}
                onChangeSelectedOption={onSelectCategory}
                dynamicOptions={true}
            />
            <Input
                placeholder={'User ID'}
                onChangeInput={onChangeUserId}
                inputValue={filters.sellerId}
                showValue={true}
                type={'text'}
            />
            <Input
                placeholder={'User Name'}
                onChangeInput={onChangeUserName}
                inputValue={filters.sellerName}
                showValue={true}
                type={'text'}
            />
            <Input
                placeholder={'Item Name'}
                onChangeInput={onChangeItemName}
                inputValue={filters.itemName}
                showValue={true}
                type={'text'}
            />
            <SelectCheckbox
                placeholder={'Type'}
                selectedOption={selectedType}
                options={LABEL_PACKS_TYPES}
                onChangeSelectedOption={onChangeSelectType}
            />
        </div>
    )
}

export default LabelPacksFilters;