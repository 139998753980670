import { AxiosResponse } from 'axios';
import { ASSETS_API_URL } from '../../../../config';
import assetsAxios from '../../../../utils/axios/assets.axios';


const tryToFetchAllAssetsTemplates = (): Promise<AxiosResponse<any>> => {
    return assetsAxios.get<any>(`${ASSETS_API_URL}/dom/asset-templates`);
};

export const AssetsTemplatesService = {
    tryToFetchAllAssetsTemplates,
};