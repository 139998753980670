import React from 'react';
import { ICarrier } from '../../../../interfaces/carriers/ICarrier';
import { STATUS_ITEMS } from '../../../../utils/constants/generating-status';
import { LABEL_ITEMS } from '../../../../utils/constants/label-types';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IShippingLabelsFilters {
    shippingLabels?: Array<any>;
    carriers: Array<ICarrier>
    selectedStatus?: string;
    selectedLabel?: string;
    selectedCarrier?: string;
    onSelectCarrier: (id: string) => void;
    onSelectStatus: (item: any, name?: string) => void;
    onSelectLabel: (item: any, name?: string) => void;
}

const ShippingLabelsFilters = ({
    carriers,
    selectedCarrier,
    selectedStatus,
    selectedLabel,
    onSelectCarrier,
    onSelectStatus,
    onSelectLabel,
}: IShippingLabelsFilters) => {
    return (
        <div>
            <div className='mb-6 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 sm:gap-1 md:gap-2 lg:gap-4'>
                <div className='sm:my-1 md:my-2 lg:my-4'>
                    <SelectCheckbox
                        name={'Carrier'}
                        placeholder={' '}
                        selectedOption={selectedCarrier}
                        options={carriers}
                        onChangeSelectedOption={onSelectCarrier}
                    />
                </div>
                <div className='sm:my-1 md:my-2 lg:my-4'>
                    <SelectCheckbox
                        name={'Status'}
                        placeholder={' '}
                        uniqueName={'status'}
                        options={STATUS_ITEMS}
                        selectedOption={selectedStatus}
                        onChangeSelectedOption={onSelectStatus}
                    />
                </div>
                <div className='sm:my-1 md:my-2 lg:my-4'>
                    <SelectCheckbox
                        name={'Label'}
                        placeholder={' '}
                        selectedOption={selectedLabel}
                        uniqueName={'Label'}
                        options={LABEL_ITEMS}
                        onChangeSelectedOption={onSelectLabel}
                    />
                </div>
            </div>
        </div>
    )
}

export default ShippingLabelsFilters;