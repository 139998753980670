import React, { useEffect, useState } from 'react';
import {
    useAppDispatch,
    useAppSelector
} from '../../app/hooks';
import { AdminGuard } from '../../guards/RolesGuard';
import {
    tryToChangeActiveMarket,
    tryToFindOperatingMarkets
} from '../../store/brokers/admin/partners/partnersSlice';
import Navigation from './Navigation';
import SelectSearchInside from '../../shared/market-select-search/select-inside-search';


const Sidebar = () => {
    const dispatch = useAppDispatch()
    const state = useAppSelector((state) => state.partners);
    const [marketsList, setMarketsList] = useState<Array<any>>([])
    const activeMarket = state?.partnerActiveMarket
    const isAdmin = AdminGuard()

    useEffect(() => {
        if (state.partnerMarkets && !isAdmin) {
            const rows =
                state.partnerMarkets && Array.isArray(state.partnerMarkets)
                    ? state.partnerMarkets.map((r) => ({ ...r, name: r?.market?.name, label: r?.market?.label, value: r?.market?._id }))
                    : [];
            const formatWithIcon = rows && rows?.length > 0 && rows?.map((row) => {
                const icon = row?.market?.flagUrl
                return {
                    ...row,
                    icon: icon
                }
            })
            if (JSON.stringify(marketsList) !== JSON.stringify(formatWithIcon)) {
                setMarketsList(formatWithIcon || []);
            }
        }
    }, [state?.partnerMarkets])

    useEffect(() => {
        if (!isAdmin) {
            dispatch(tryToFindOperatingMarkets(null)).unwrap()
        }
    }, [])

    const onCheckActiveMarket = async () => {
        const localMarket = localStorage.getItem('activeMarket')
        const formatLocalMarket = localMarket && JSON.parse(localMarket)
        if (activeMarket) {
            const findActiveMarket = marketsList && marketsList?.length > 0 && marketsList?.find((market) => market?.market?._id === activeMarket?.market?._id)
            if (!findActiveMarket && marketsList && marketsList?.length > 0 && activeMarket) {
                dispatch(tryToChangeActiveMarket({ marketId: marketsList?.[0]?.market?._id || '' }))
            }
        } else if (formatLocalMarket) {
            const findActiveMarket = marketsList && marketsList?.length > 0 && marketsList?.find((market) => market?.market?._id === formatLocalMarket?.market?._id)
            if (!findActiveMarket && marketsList && marketsList?.length > 0) {
                dispatch(tryToChangeActiveMarket({ marketId: marketsList?.[0]?.market?._id || '' }))
            } else if (findActiveMarket) {
                dispatch(tryToChangeActiveMarket({ marketId: findActiveMarket?.market?._id || '' }))
            }
        }
        else if (marketsList && marketsList?.length > 0) {
            dispatch(tryToChangeActiveMarket({ marketId: marketsList?.[0]?.market?._id || '' }))
        }
    }

    useEffect(() => {
        if (marketsList) {
            onCheckActiveMarket()
        }
    }, [marketsList])

    const onChangeSelectedValue = async (value: any) => {
        const findMarketId = marketsList?.find((market) => market?.name === value?.name)
        if (findMarketId?.market?._id !== activeMarket?.market?._id) {
            dispatch(tryToChangeActiveMarket({ marketId: findMarketId?.market?._id || '' }))
        }
    }

    return (
        <div className='hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 pt-24'>
            <div className='flex flex-col flex-grow pt-5 bg-white overflow-y-auto'>
                {!isAdmin &&
                    <div className='flex flex-col px-4 pr-5 mt-1 mb-2'>
                        <SelectSearchInside
                            selectedOption={{ label: activeMarket?.market?.label, value: activeMarket?.market?._id, icon: activeMarket?.market?.flagUrl }}
                            options={marketsList || []}
                            onChangeSelectedOption={onChangeSelectedValue}
                            placeholder={activeMarket?.market?.label || 'Markets'}
                        />
                    </div>
                }
                <div className='flex items-center flex-shrink-0 px-4'>
                    {/* <form className='w-full flex md:ml-0' action='#' method='GET'>
                        <label htmlFor='search-field' className='sr-only'>
                            Search
                        </label>

                        <div className='relative w-full text-gray-400 focus-within:text-gray-600'>
                            <div className='absolute inset-y-0 left-2 flex items-center pointer-events-none'>
                                <img alt='search' className='h-4 w-4' src='/svg/layout/search-icon.svg' />
                            </div>
                            <input
                                id='search-field'
                                className='bg-bgColor-search min-w-[99%] rounded block h-full pl-8 pr-3 py-2 border-transparent placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm'
                                placeholder='Search'
                                type='search'
                                name='search'
                            />
                        </div>
                    </form> */}
                </div>
                <div className='mt-8 flex-1 flex flex-col'>
                    <nav className='flex-1 px-4 pb-4'>
                        <Navigation />
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;