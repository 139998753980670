import axios from 'axios';
import { validateJWT } from './../validateJWT';
import { readFromStorage, removeFromStorage } from './../webStorage';

// declaration to support proper headers and allow handlers
declare module 'axios' {
	export interface AxiosRequestConfig {
		handlerEnabled?: boolean;
		config?: { headers: AxiosRequestConfig['headers'] };
	}
}

axios.defaults.config = {
	headers: {
		'Access-Control-Allow-Origin': '*',
	},
};

export const setupAxios = () => {
	const logout = () => {
		removeFromStorage('token');
		window.location.reload();
	};

	// reqeust intercpetor
	axios.interceptors.request.use((config) => {
		// get token
		const token = readFromStorage('token');
		const isValid = validateJWT(token);

		// config token as default per request
		if (isValid) {
			return {
				...config,
				headers: {
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${token}`,
				},
			};
		} else {
			logout();
		}
		return config;
	});

	// Add a response interceptor
	axios.interceptors.response.use(
		(response) => {
			// if error: 'No permission!'
			// logout & go to login page
			if (response?.data?.error === 'No permission!') {
				logout();
			}

			return response;
		},
		(error) => {
			const err = { ...error };

			// if (err?.response?.status === 401) {
			//   // dispatch logout action
			//   logout();
			// }
			//  else if (err?.response?.status === 403) {
			//   // Forbiden Request replace with main
			//   window.location.assign('/login');
			// } else if (err?.response?.status === 404) {
			//   // route not found
			//   window.location.assign('/error/404');
			// } else if (!err?.response) {
			//   window.location.assign('/error/404');
			// }

			return Promise.reject(err);
		},
	);
};

export const softSetupAxios = (token: string) => {
	// reqeust intercpetor
	axios.interceptors.request.use((config) => {
		// config token as default per request
		return {
			...config,
			headers: {
				...config.headers,
				Authorization: `Bearer ${token}`,
			},
		};
	});
};

export default axios;
