import React from 'react';
import { ICarrier } from '../../../../interfaces/carriers/ICarrier';
import WhiteContainer from '../../../../shared/white-container';


interface ICarrierItem {
    carrierDetails: ICarrier;
    onClickCarrier: (carrierId: string) => void;
}

const CarrierItem = ({
    carrierDetails,
    onClickCarrier
}: ICarrierItem) => {
    return (
        <WhiteContainer
            type='rounded'
            containerStyle='min-h-[250px] min-w-[100%]'
        >
            <div
                onClick={() => onClickCarrier(carrierDetails?._id || '')}
                className='p-4 cursor-pointer hover:opacity-70 flex flex-col justify-between items-center min-h-[100%]'>
                <div className='mb-4'>
                    <p className='font-medium'>{carrierDetails?.displayName}</p>
                </div>
                {carrierDetails.logo && <img src={carrierDetails.logo} alt='' />}
            </div>
        </WhiteContainer>
    )
}

export default CarrierItem;