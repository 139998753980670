import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import { IPartnersSchema } from '../../../../interfaces/partners/IPartnersSchema';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IAddress } from '../../../../interfaces/auth/ICreateUserSchema';


const tryToFetchAllPartners = (): Promise<AxiosResponse<IPartners[]>> => {
    return brokersAxios.get(`${USERS_API_URL}/partners?flag=true`);
};

const tryToAddPartner = (data: IPartners): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/partners`, data);
};

const tryToRequestToRegisterPartner = (data: IPartners): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/dom/partners`, data);
};

const tryToEnablePartner = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/${id}/enable`);
};


const tryToEditAddressPartner = (payload: IAddress): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/change-address/${payload?.id}`, payload);
};

const tryToSetAddressDefault = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/set-default-address/${id}`);
};

const tryToDisablePartner = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/partners/${id}`);
};

const tryToFetchSinglePartner = (id: string): Promise<AxiosResponse<ApiResponse<IPartnersSchema>>> => {
    return brokersAxios.get<ApiResponse<any>>(`${USERS_API_URL}/partners/${id}`);
};

const tryToAddMarket = (data: { id: string }): Promise<AxiosResponse<ApiResponse<IPartnersSchema>>> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/add-market`, data)
}

const tryToDisableMarket = (marketId: string): Promise<AxiosResponse<ApiResponse<IPartnersSchema>>> => {
    return brokersAxios.delete(`${USERS_API_URL}/partners/disable-market/${marketId}`)
}

const tryToFindOperatingMarkets = (): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/users/markets/operating`)
}

const tryToGetPartnerSchdulerSettings = (partnerId: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/partners/${partnerId}/schedulers`)
}

const tryToEnableInventoryFeature = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/${id}/enable-inventory`);
};

const tryToDisableInventoryFeature = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/${id}/disable-inventory`);
};

export const PartnersService = {
    tryToFetchAllPartners,
    tryToFetchSinglePartner,
    tryToFindOperatingMarkets,
    tryToAddPartner,
    tryToAddMarket,
    tryToEditAddressPartner,
    tryToSetAddressDefault,
    tryToEnablePartner,
    tryToEnableInventoryFeature,
    tryToDisableInventoryFeature,
    tryToDisablePartner,
    tryToDisableMarket,
    tryToRequestToRegisterPartner,
    tryToGetPartnerSchdulerSettings
};
