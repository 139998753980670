import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { AdminGuard } from '../../guards/RolesGuard';
import { classNames } from '../../utils/classNames';

const navigationAgents = [
    { name: 'Carriers', href: '/broker-carriers', icon: <img alt='broker-carriers' src='/assets/navigation-icons/carriers.svg' />, iconActive: <img alt='carriers-active' src='/assets/navigation-icons/carriers-black.svg' />, enabled: true },
    { name: 'Shipping Labels', href: '/shipping-labels', icon: <img alt='shipping-labels' src='/assets/navigation-icons/shipping-labels.svg' />, iconActive: <img alt='shipping-labels-active' src='/assets/navigation-icons/shipping-labels-black.svg' />, enabled: true },
    { name: 'Label Packs', href: '/label-packs', icon: <img alt='label-packs' src='/assets/navigation-icons/label-packs.svg' />, iconActive: <img alt='label-packs-active' src='/assets/navigation-icons/label-packs-black.svg' />, enabled: true }
]

const navigationAdmin = [
    { name: 'Carriers', href: '/carriers', icon: <img alt='admin-carriers' src='/assets/navigation-icons/carriers.svg' />, iconActive: <img alt='carriers-active' src='/assets/navigation-icons/carriers-black.svg' />, enabled: true },
]

// const extraNavigation = [
//     { name: 'Profile', href: '/profile', icon: <img alt='profile' src='/assets/navigation-icons/profile.svg' />, iconActive: <img alt='profile-active' src='/assets/navigation-icons/profile-black.svg' />, enabled: true },
//     // { name: 'Settings', href: '/', icon: <img alt='settings' src='/assets/navigation/setting.svg' />, enabled: true },
// ]

const Navigation = () => {
    const location = useLocation();
    const isAdmin = AdminGuard()
    const [activeRoute, setActiveRoute] = useState<string>()

    useEffect(() => {
        setActiveRoute(location.pathname)
    }, [location])

    useEffect(() => {
        setActiveRoute(location?.pathname)
    }, [])

    return (
        <>
            {isAdmin ?
                <>
                    {navigationAdmin.map((item) => (
                        <NavLink
                            key={item.name}
                            to={item.href}
                            className={({ isActive }) => classNames('text-base text-secondary group flex items-center px-2 py-2 font-medium rounded-md mb-2',
                                (item.enabled && isActive) ? 'font-dm_sansbold !text-[#202020] bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !rounded-lg' : 'font-dm_sansregular'
                            )}
                        >
                            {/* <item.icon className='mr-3 flex-shrink-0 h-6 w-6 text-indigo-300' aria-hidden='true' /> */}
                            <span className='flex mr-2 flex-shrink-0 h-6 w-6 justify-center items-center'>
                                {activeRoute === item?.href ?
                                    React.cloneElement(item.iconActive || item.icon, {
                                        width: 20,
                                        height: 20,
                                    })
                                    : React.cloneElement(item.icon, {
                                        width: 20,
                                        height: 20,
                                    })
                                }
                            </span>
                            {item.name}
                        </NavLink>
                    ))}
                </>
                :
                <>
                    {navigationAgents.map((item) => (
                        <NavLink
                            key={item.name}
                            to={item.href}
                            className={({ isActive }) => classNames('text-base text-secondary group flex items-center px-2 py-2 font-medium rounded-md mb-2',
                                (item.enabled && isActive) ? 'font-dm_sansbold !text-[#202020] bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !rounded-lg' : 'font-dm_sansregular'
                            )}
                        >
                            {/* <item.icon className='mr-3 flex-shrink-0 h-6 w-6 text-indigo-300' aria-hidden='true' /> */}
                            <span className='flex mr-2 flex-shrink-0 h-6 w-6 justify-center items-center'>
                                {activeRoute === item?.href ?
                                    React.cloneElement(item.iconActive || item.icon, {
                                        width: 20,
                                        height: 20,
                                    })
                                    : React.cloneElement(item.icon, {
                                        width: 20,
                                        height: 20,
                                    })
                                }
                            </span>
                            {item.name}
                        </NavLink>
                    )
                    )}
                    <div className='mt-14'>
                        {/* {extraNavigation.map((item) => (
                            <NavLink
                                key={item.name}
                                to={item.href}
                                className={({ isActive }) => classNames('text-base text-secondary group flex items-center px-2 py-2 font-medium rounded-md mb-2',
                                    (item.enabled && isActive) ? 'font-dm_sansbold !text-[#202020] bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !rounded-lg' : 'font-dm_sansregular'
                                )}
                            >
                                <span className='flex mr-2 flex-shrink-0 h-6 w-6 justify-center items-center'>
                                    {activeRoute === item?.href ?
                                        React.cloneElement(item.iconActive || item.icon, {
                                            width: 20,
                                            height: 20,
                                        })
                                        : React.cloneElement(item.icon, {
                                            width: 20,
                                            height: 20,
                                        })
                                    }
                                </span>
                                {item.name}
                            </NavLink>
                        ))} */}
                    </div>
                </>
            }
        </>
    )
}

export default Navigation;