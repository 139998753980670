import React from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { ILabelPacksSchema } from '../../../../interfaces/label-packs/ILabelPacksSchema';
import Table from '../../../../shared/table';

const columns = ['User ID', 'First & Last Name', 'Order ID', 'Item Name', 'Type', 'Status', 'Inbound Label', 'Outbound Label', 'Actions']

interface ITable {
    rows?: ILabelPacksSchema[];
    paginationData?: IPage;
    setPaginationState?: any;
    selectedRows?: Array<string>;
    rowsLoading: boolean;
    onClickChangeLabelPackStatus: (newStatus: string, id: string) => void;
    onClickSelectRow: (id?: string) => void;
}

const LabelPacksTable = ({
    rows,
    paginationData,
    selectedRows,
    setPaginationState,
    rowsLoading,
    onClickSelectRow,
    onClickChangeLabelPackStatus,
}: ITable) => {
    return (
        <Table
            selectRows={true}
            areAllRowsSelected={(selectedRows && rows && selectedRows?.length === rows?.length)}
            columns={columns}
            paginationData={paginationData}
            onClickSelectAll={() => onClickSelectRow()}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(6)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(columns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </> :
                <>
                    {rows && rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        const createdIconUrl = '/assets/table-actions/check-circle.svg'
                        const completedIconUrl = '/assets/table-actions/check-circle-green.svg'
                        const findIfRowIsSelected = selectedRows && selectedRows?.length > 0 && selectedRows?.find((item) => item === row?._id)
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>
                                    <div className='flex items-center'>
                                        <input
                                            onChange={() => onClickSelectRow(row?._id)}
                                            checked={!!findIfRowIsSelected}
                                            id='checkbox-all'
                                            type='checkbox'
                                            className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2' />
                                        <label htmlFor='checkbox-all' className='sr-only'>checkbox</label>
                                    </div>
                                </td>
                                <td className='px-6 py-4'>{row?.sellerId}</td>
                                <td className='px-6 py-4'>{row?.user?.name}</td>
                                <td className='px-6 py-4'>{row?.orderId}</td>
                                <td className='px-6 py-4'>{row?.itemName}</td>
                                <td className='px-6 py-4'>{row?.type === 'regular' ? 'Regular' : 'Instant'}</td>
                                <td className='px-6 py-4'>{`${row?.status[0].toUpperCase()}${row?.status?.slice(1)?.toLowerCase()}`}</td>
                                <td className='px-6 py-4 '><a href={row?.inboundLabel.url} className='underline underline-text-[#F7931E] hover:text-[#F7931E]' target='_blank' rel='noreferrer'>{row?.inboundLabel.trackingNumber}</a></td>
                                <td className='px-6 py-4 '><a href={row?.outboundLabel.url} className='underline underline-text-[#F7931E] hover:text-[#F7931E]' target='_blank' rel='noreferrer'>{row?.outboundLabel.trackingNumber ? row?.outboundLabel.trackingNumber : 'Outbound Label URL'}</a></td>
                                <td className='px-6 py-4'>
                                    {row?.status === 'created'
                                        ? <div onClick={() => row?._id && onClickChangeLabelPackStatus('completed', row?._id)}>
                                            <img title={'Complete Item'} alt='Complete item' src={createdIconUrl} className={'h-[20px] object-cover cursor-pointer mx-auto'} />
                                        </div>
                                        : <div onClick={() => row?._id && onClickChangeLabelPackStatus('created', row?._id)}>
                                            <img title={'Ope Item'} alt='Open item' src={completedIconUrl} className={'h-[20px] object-cover cursor-pointer mx-auto'} />
                                        </div>
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    );
};

export default LabelPacksTable;