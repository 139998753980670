import React from 'react';
import Table from '../../../../shared/table';
import Switch from '../../../../shared/switch';

export interface Service {
  _id: string;
  carrierId?: any;
  serviceId?: any;
  name: string;
  description?: string;
  status: string[];
}
const tableColumns = ['Id', 'Name', 'Description', 'Inbound Status', 'Outbound Status', 'Inventory Status', 'Actions'];

interface ICarriersTable {
  rows: any;
  setSelectedRow?: any;
  // rowsLoading: boolean;
  onEnableDisableFeatures: (carrierId: string, serviceId: string, type: string, status: string[]) => void;
  onDeleteCarrierService: (carrierId: string, serviceId: string) => void;
  onEditCarrierServiceDetails: (row: any, carrierId: string, serviceId: string) => void;
}

const CarriersServiceTable = ({
  rows,
  onEnableDisableFeatures,
  onEditCarrierServiceDetails,
  onDeleteCarrierService,
  setSelectedRow
}: ICarriersTable) => {
  return (
    <Table columns={tableColumns} hidePagination={true} containerStyle={'mt-0'}>
      {
        rows?.services?.map((service: Service, idx: number) => (
          <tr key={idx} className="bg-white border-b hover:bg-gray-50">
            <td className="px-6 py-4">{service?._id}</td>
            <td className="px-6 py-4">{service?.name}</td>
            <td className="px-6 py-4">{service?.description}</td>
            <td className='px-6 py-4'>
              <Switch
                checked={!!service?.status?.includes('inbound')}
                onToggleSwitch={() => service?._id && onEnableDisableFeatures(rows?._id, service?._id, 'inbound', service.status || [])}
              />
            </td>
            <td className='px-6 py-4'>
              <Switch
                checked={!!service?.status?.includes('outbound')}
                onToggleSwitch={() => service?._id && onEnableDisableFeatures(rows?._id, service?._id, 'outbound', service.status || [])}
              />
            </td>
            <td className='px-6 py-4'>
              <Switch
                checked={!!service?.status?.includes('inventory')}
                onToggleSwitch={() => service?._id && onEnableDisableFeatures(rows?._id, service?._id, 'inventory', service.status || [])}
              />
            </td>
            <td className="px-6 py-4">
              <div className="flex flex-row items-center">
                <div onClick={() => service?._id && onEditCarrierServiceDetails(service, service?.carrierId, service?._id)}>
                  <img
                    title='Edit Carrier Service'
                    alt='Edit Carrier Service'
                    src={'/assets/table-actions/pen.svg'}
                    className={'h-[14px] object-contain cursor-pointer mr-3'}
                  />
                </div>
                <div
                  onClick={() => service?._id && onDeleteCarrierService(service?.carrierId, service?._id)}
                >
                  <img
                    title={'Delete Carrier Service'}
                    alt='Delete Carrier Service'
                    src={'/assets/table-actions/delete-close.svg'}
                    className={'h-[14px] object-contain cursor-pointer mr-1'}
                  />
                </div>
              </div>
            </td>
          </tr>
        ))}
    </Table>
  );
};

export default CarriersServiceTable;
