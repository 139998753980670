import React from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';
import { ICarrier } from '../../../../interfaces/carriers/ICarrier';

const tableColumns = ['Id', 'Name', 'Display Name', 'Logo', 'Actions'];

interface ICarriersTable {
  rows: ICarrier[];

  paginationData?: IPage;
  rowsLoading: boolean;
  onDeleteCarrierRequest: (id: string) => void;
  onEditCarrierField: (id: string) => void;
  onSeeCarrierDetails: (id: string) => void;
  onSeeCarrierServices: (id: string) => void;
  setPaginationState?: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const CarriersTable = ({
  rows,
  paginationData,
  rowsLoading,
  setPaginationState,
  onDeleteCarrierRequest,
  onEditCarrierField,
  onSeeCarrierDetails,
  onSeeCarrierServices,
}: ICarriersTable) => {
  return (
    <Table
      columns={tableColumns}
      paginationData={paginationData}
      setPaginationState={setPaginationState}
      containerStyle={'mt-0'}
    >
      {rowsLoading ? (
        <>
          {[...Array(4)].map((x, i) => {
            return (
              <tr key={i}>
                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                  return (
                    <td key={j} className="td-1 !min-w-[100%] text-center py-5">
                      <span></span>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </>
      ) : (
        <>
          {(rows && rows?.length > 0) && rows?.map((row: ICarrier, idx: number) => {
            return (
              <tr key={idx} className="bg-white border-b hover:bg-gray-50">
                <td className="px-6 py-4">{row?._id}</td>
                <td className="px-6 py-4">{row?.name}</td>
                <td className="px-6 py-4">{row?.displayName}</td>
                <td className="px-6 py-4">
                  {row?.logo && <img src={row?.logo} className={'mr-2 py-2 w-[20px]'} />}
                </td>
                <td className="px-6 py-4">
                  <div className="flex flex-row items-center">
                    <div onClick={() => row?._id && onSeeCarrierDetails(row?._id)}>
                      <img
                        title={'View Details'}
                        alt="Carrier details"
                        src={'/assets/table-actions/visibility.svg'}
                        className={'h-[14px] object-contain cursor-pointer mr-2'}
                      />
                    </div>
                    <div onClick={() => row?._id && onEditCarrierField(row?._id)}>
                      <img
                        title="Edit Carrier"
                        alt="Edit Carrier"
                        src={'/assets/table-actions/pen.svg'}
                        className={'h-[14px] object-contain cursor-pointer mr-3'}
                      />
                    </div>
                    <div onClick={() => row?._id && onDeleteCarrierRequest(row?._id)}>
                      <img
                        title={'Delete Carrier'}
                        alt="Delete Carrier"
                        src={'/assets/table-actions/delete-close.svg'}
                        className={'h-[14px] object-contain cursor-pointer mr-1'}
                      />
                    </div>
                    <div onClick={() => row?._id && onSeeCarrierServices(row?._id)}>
                      <img
                        title={'View Services'}
                        alt="Carrier Services"
                        src={'/assets/table-actions/local-offer.svg'}
                        className={'h-[14px] object-contain cursor-pointer mr-2'}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </>
      )}
    </Table>
  );
};

export default CarriersTable;
