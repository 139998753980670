import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ICarrier } from '../../../interfaces/carriers/ICarrier';
import { IActiveMarket } from '../../../interfaces/markets/IActiveMarket';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { tryToFetchAllShipmentLabels } from '../../../store/brokers/admin/shipping-labels/shippingLabelsSlice';
import { tryToFetchAllPartnerCarriersByMarket } from '../../../store/brokers/partner/partner-carriers/partnerCarriersSlice';
import ShippingLabelsFilters from './details/ShippingLabelsFilters';
import ShippingLabelsTable from './tables/ShippingLabelsTable';
import SearchWithButton from '../../../shared/search-with-button';
import EmptyContainer from '../../../shared/empty-container';


const ShippingLabels = () => {
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.shippingLabels);
    const [carriers, setCarriers] = useState<ICarrier[]>();
    const [selectedCarrier, setSelectedCarrier] = useState<any>();
    const [selectedStatus, setSelectedStatus] = useState<any>();
    const [selectedLabel, setSelectedLabel] = useState<any>();
    const [rows, setRows] = useState<any[]>([]);
    const [search, setSearch] = useState<string>();
    const [activeMarket, setActiveMarket] = useState<IActiveMarket>()
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket);
    const paginationData = useAppSelector((state) => state?.shippingLabels?.shippingLabels?.data.page);
    const rowsLoading = useAppSelector((state) => state?.shippingLabels?.shippingLabelsAreLoading);

    useEffect(() => {
        if (state.shippingLabels) {
            const rows =
                state.shippingLabels?.data?.elements && Array.isArray(state.shippingLabels?.data.elements)
                    ? state.shippingLabels?.data.elements.map((r) => ({ ...r }))
                    : [];
            setRows(rows);
        }
    }, [state.shippingLabels]);

    useEffect(() => {
        if (activeMarketState && (JSON.stringify(activeMarketState) !== JSON.stringify(activeMarket))) {
            setActiveMarket(activeMarketState || undefined)
        }
    }, [activeMarketState])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    const onSelectCarrier = (value: string) => {
        setSelectedCarrier(value)
        onResetPaginationState()
    }

    const onSelectStatus = (item: any, name?: string) => {
        setSelectedStatus(item)
        onResetPaginationState()
    }

    const onSelectLabel = (item: any, name?: string) => {
        setSelectedLabel(item)
        onResetPaginationState()
    }

    const onGettingAllCarriers = async () => {
        const data = {
            marketId: activeMarket?.market?._id || ''
        }
        const carrierResponse = await dispatch(tryToFetchAllPartnerCarriersByMarket(data)).unwrap()
        const formatCarriers = carrierResponse && carrierResponse?.length > 0 && carrierResponse?.map((carrier: ICarrier) => ({ ...carrier, name: carrier?.carrier?.name, label: carrier?.carrier?.displayName, value: carrier?.carrier?._id }))
        setCarriers(formatCarriers || [])
    }

    const onGetAllShipmentLabels = async () => {
        if (activeMarket) {
            let data: any = {
                ...paginationState || {},
            }
            if (selectedCarrier) {
                data = { ...data || {}, data: { carrierName: selectedCarrier?.name } }
            }
            if (selectedLabel) {
                data = { ...data || {}, data: { ...data?.data || {}, labelType: selectedLabel?.name } }
            }
            if (selectedStatus) {
                data = { ...data || {}, data: { ...data?.data || {}, succeeded: selectedStatus?.name === 'Success' } }
            }
            if (activeMarket) {
                data = { ...data || {}, data: { ...data?.data || {}, country: activeMarket?.market?.name } }
            }
            if (search) {
                data = { ...data || {}, data: { ...data?.data || {}, trackingNumber: search } }
            }
            await dispatch(tryToFetchAllShipmentLabels(data))
        }
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    const clearAllFilters = () => {
        setSelectedCarrier(undefined)
        setSelectedStatus(undefined)
        setSelectedLabel(undefined)
        setSearch(undefined)
        onResetPaginationState()
    }

    useEffect(() => {
        if (activeMarket) {
            onGettingAllCarriers()
            onGetAllShipmentLabels()
        }
    }, [activeMarket])

    useEffect(() => {
        onGetAllShipmentLabels();
    }, [paginationState]);

    return (
        <div>
            <div className='mb-6'>
                <p className='page-title'>Shipping labels</p>
            </div>
            <div>
                <ShippingLabelsFilters
                    carriers={carriers!}
                    selectedStatus={selectedStatus}
                    selectedLabel={selectedLabel}
                    selectedCarrier={selectedCarrier}
                    onSelectCarrier={onSelectCarrier}
                    onSelectStatus={onSelectStatus}
                    onSelectLabel={onSelectLabel}
                />
            </div>
            <div>
                <SearchWithButton
                    placeholder='Filter Table'
                    buttonLabel='Clear Filters'
                    buttonStyle='btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end'
                    onChangeSearchValue={onChangeSearchValue}
                    search={search}
                    inputStyle={'bg-transparent'}
                    onClickButton={clearAllFilters}
                    mainContainerStyle={'!bg-white !grid-cols-10'}
                />
            </div>
            <div>
                {(rows?.length > 0 || rowsLoading) ?
                    <ShippingLabelsTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                    />
                    : <EmptyContainer
                        text='No shipping labels created yet.'
                        showImage={true}
                    />
                }
            </div>
        </div>
    )
}
export default ShippingLabels