import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-template/assetsTemplatesSlice';
import { tryToAddPartner } from '../../../store/brokers/admin/partners/partnersSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import LayoutRegister from './Layout/LayoutRegister';
import SuccessRegisterModal from './modals/SuccessRegisterModal';
import DetailsStep from './steps/DetailsStep';
import EmailStep from './steps/EmailStep';

const Register = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [sectors, setSectors] = useState<Array<any>>([]);
  const [markets, setMarkets] = useState<Array<any>>([]);
  const [emailError, setEmailError] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false);
  const [actualSlide, setActualSlide] = useState<number>(1);
  const [createError, setCreateError] = useState<string>();
  const [getAllMarkets] = useGetAllMarketsMutation();
  const state = useAppSelector((state) => state?.assetsTemplates);

  const getMarketsData = async () => {
    const response = await getAllMarkets(null).unwrap();
    const formatMarkets =
      response &&
      response?.length > 0 &&
      response?.map((market: any) => ({ ...market, value: market?._id }));
    setMarkets(formatMarkets || []);
  };

  useEffect(() => {
    dispatch(tryToFetchAllAssetsTemplates());
    getMarketsData();
  }, []);

  useEffect(() => {
    if (state.allAssetTemplates) {
      const rows =
        state.allAssetTemplates && Array.isArray(state.allAssetTemplates)
          ? state.allAssetTemplates.map((r) => ({
            label: r?.displayName,
            value: r?._id,
            ...(r || {}),
          }))
          : [];
      setSectors(
        rows?.length > 0
          ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies')
          : []
      );
    }
  }, [state.allAssetTemplates]);

  const onChangeInput = (value: string | null | undefined, type: string) => {
    setEmail(value || undefined);
    setEmailError(undefined);
  };

  const onGoToLogin = () => {
    navigate('/login');
  };

  const onGoToNext = () => {
    if (
      !email ||
      email === '' ||
      !(email || '').match(
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setEmailError('Please write a valid email.');
      return;
    }
    setActualSlide(2);
  };

  const onRequestToRegisterPartner = async (
    data: IPartners
  ): Promise<boolean> => {
    const payload = {
      ...data,
      email: email || '',
    };
    try {
      await dispatch(tryToAddPartner(payload)).unwrap();
      navigate('/login');
      setCreateError(undefined);
      setEmail(undefined);
      setShowRegisterModal(true);
      return true;
    } catch (error) {
      setCreateError(`${error}`);
      return false;
    }
  };

  return (
    <LayoutRegister>
      <EmailStep
        registerEmail={email}
        onGoToLogin={onGoToLogin}
        onGoToNext={onGoToNext}
        onChangeInput={onChangeInput}
        step={actualSlide}
        error={emailError}
      />
      <DetailsStep
        createError={createError}
        step={actualSlide}
        sectorItems={sectors}
        marketItems={markets}
        onRequestToRegisterPartner={onRequestToRegisterPartner}
      />
      <SuccessRegisterModal
        openModal={showRegisterModal}
        handleCloseModal={() => setShowRegisterModal(false)}
      />
    </LayoutRegister>
  );
};

export default Register;
