import React from 'react';


interface IModal {
    open: boolean;
    onClose: () => void;
    children?: React.ReactNode;
    title?: string;
    contentContainerStyle?: string;
}

const Modal = ({
    open,
    onClose,
    children,
    title,
    contentContainerStyle
}: IModal) => {
    return (
        <div className={`fixed z-20 inset-0 ${open ? '' : 'pointer-events-none'} `}>
            {/* backdrop */}
            <div className={`fixed inset-0 bg-black ${open ? 'opacity-50' : 'pointer-events-none opacity-0'}`}
                onClick={onClose} />

            {/* content */}
            <div className={`fixed overflow-auto overscroll-auto !max-h-[95vh] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg w-auto min-w-[200px] h-auto min-h-[100px] max-w-screen-sm p-4 ${open ? 'opacity-100' : 'pointer-events-none opacity-0'} ${contentContainerStyle || ''}`}>
                <div className='flex flex-row justify-between items-center mt-1 mb-2'>
                    <p className='text-[#5C5C5C] text-[15px]'>{title || ''}</p>
                    <div onClick={onClose} className={'cursor-pointer'}>
                        <img alt='close' src={'/assets/shared/modal-close.svg'} className='object-contain w-[16px]' />
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};

export default Modal;