import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


const EditCarrierFieldContainer = () => {
    return (
        <WhiteContainer>
            <div className='flex flex-col my-4'>
                <div className='mt-3 mb-6 animate-pulse'>
                    <div className='h-8 bg-gray-200 rounded dark:bg-gray-200 min-w-[100%] mb-1'></div>
                </div>
                <div className='mt-3 mb-6 flex flex-row items-center animate-pulse'>
                    <div className='h-3 bg-gray-200 rounded dark:bg-gray-200 w-[100px] mr-2'></div>
                    <div className='h-7 bg-gray-200 rounded-full dark:bg-gray-200 w-[50px]'></div>
                </div>
                <div className='flex flex-row justify-end mt-4 animate-pulse'>
                    <div className='h-8 bg-gray-200 rounded dark:bg-gray-200 w-[150px] mb-1'></div>
                </div>

            </div>
        </WhiteContainer>
    )
}

export default EditCarrierFieldContainer;