import React from 'react';
import {
  AdminGuard,
  GuestGuard,
  PartnerAgentGuard,
} from '../guards/RolesGuard';
import Register from '../features/auth/register';
import Login from '../features/auth/login';
import Layout from '../container/Layout';
import ResetPassword from '../features/auth/reset-password';
import Profile from '../features/dashboard/profile';
import Carriers from '../features/admin-dashboard/carriers';
import CreateCarrier from '../features/admin-dashboard/carriers/create';
import CarrierDetails from '../features/admin-dashboard/carriers/details';
import CarrierServices from '../features/admin-dashboard/carriers/services';
import BrokerCarriers from '../features/dashboard/partner-carriers';
import ShippingLabels from '../features/dashboard/shipping-labels';
import LabelPacks from '../features/dashboard/label-packs';

export interface RouteDefinition {
  path: string;
  element: JSX.Element;
  guard?: () => boolean;
  index?: string;
  childrens?: RouteDefinition[];
}

export const routes: RouteDefinition[] = [
  // Guest pages should have GuestGuard so everyone can access
  {
    path: '/login',
    element: <Login />,
    guard: GuestGuard,
  },
  {
    path: '/register',
    element: <Register />,
    guard: GuestGuard,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    guard: GuestGuard,
  },
  // Admin pages should have AdminGuard
  // {
  //     path: '/admin-home',
  //     element: <Layout />,
  //     index: '/admin-home',
  //     guard: AdminGuard,
  //     childrens: [
  //         {
  //             path: '',
  //             element: <AdminHome />
  //         },
  //         {
  //             path: 'create',
  //             element: <AdminCreatePage />
  //         },
  //         {
  //             path: 'edit/:id',
  //             element: <AdminCreatePage />
  //         },
  //         {
  //             path: ':id',
  //             element: <AdminDetailsPage />
  //         }
  //     ],
  // },
  {
    path: '/carriers',
    element: <Layout />,
    index: '/carriers',
    guard: AdminGuard,
    childrens: [
      {
        path: '',
        element: <Carriers />,
      },
      {
        path: 'create',
        element: <CreateCarrier />,
      },
      {
        path: 'details/:id',
        element: <CarrierDetails />,
      },
      {
        path: 'services/:id',
        element: <CarrierServices />,
      },
      // {
      //     path: 'edit/:id',
      //     element: <AdminCreatePage />
      // },
      // {
      //     path: ':id',
      //     element: <AdminDetailsPage />
      // }
    ],
  },
  // Brokers/Partners should have PartnerAgentGuard for each different role user should be a guard
  // {
  //     path: '/home',
  //     element: <Layout />,
  //     index: '/home',
  //     guard: PartnerAgentGuard,
  //     childrens: [
  //         {
  //             path: '',
  //             element: <Home />
  //         }
  //     ],
  // },
  {
    path: '/broker-carriers',
    element: <Layout />,
    index: '/broker-carriers',
    guard: PartnerAgentGuard,
    childrens: [
      {
        path: '',
        element: <BrokerCarriers />,
      },
    ],
  },
  {
    path: '/shipping-labels',
    element: <Layout />,
    index: '/shipping-labels',
    guard: PartnerAgentGuard,
    childrens: [
      {
        path: '',
        element: <ShippingLabels />,
      },
    ],
  },
  {
    path: '/label-packs',
    element: <Layout />,
    index: '/label-packs',
    guard: PartnerAgentGuard,
    childrens: [
      {
        path: '',
        element: <LabelPacks />,
      },
    ],
  },
  {
    path: '/profile',
    element: <Layout />,
    index: '/profile',
    guard: PartnerAgentGuard,
    childrens: [
      {
        path: '',
        element: <Profile />,
      },
    ],
  },
];
