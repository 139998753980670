import React from 'react';
import moment from 'moment';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import Table from '../../../../shared/table';

const columns = ['Carrier Name', 'Label Type', 'Date', 'Succeeded', 'Tracking Number', 'Message', 'Shipping Label']

interface ITable {
    rows?: any;
    rowsLoading: boolean;
    paginationData?: IPage;
    setPaginationState?: any;
}

const ShippingLabelsTable = ({
    rows,
    rowsLoading,
    paginationData,
    setPaginationState,
}: ITable) => {
    return (
        <Table
            columns={columns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(columns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </> :
                <>
                    {rows && rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.carrierName}</td>
                                <td className='px-6 py-4'>{row?.labelType}</td>
                                <td className='px-6 py-4'>{row?.updatedAt && moment(row?.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                <td className='px-6 py-4'>{row?.succeeded?.toString()}</td>
                                <td className='px-6 py-4'>{row?.trackingNumber}</td>
                                <td className='px-6 py-4'>{row?.errorMessage ? row?.errorMessage : 'No Error Message Found'}</td>
                                <a href={row?.shippingLabel} rel='noreferrer' className='underline underline-text-[#F7931E] hover:text-[#F7931E]' target='_blank'><td className='px-6 py-4 '>{row?.shippingLabel}</td></a>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    );
};

export default ShippingLabelsTable;