import { AxiosResponse } from 'axios';
import { CARRIERS_API_URL } from '../../../../config';
import { PaginatedPartnerCarriers } from '../../../../interfaces/partner-carriers/paginatedStore.type';
import carriersAxios from '../../../../utils/axios/carriers.axios';


const tryToFetchAllShipmentLabels = (
    pageNumber: number,
    pageSize: number,
    data?: any
): Promise<AxiosResponse<PaginatedPartnerCarriers>> => {
    const url = `${CARRIERS_API_URL}/shipment/labels?page=${pageNumber}&size=${pageSize}`
    return carriersAxios.put<PaginatedPartnerCarriers>(url, data);
};


export const ShippingLabelsService = {
    tryToFetchAllShipmentLabels,
};