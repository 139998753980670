import React from 'react';
import Table from '../../../../shared/table';
import { ICarrierField } from '../../../../interfaces/carriers/ICarrierField';

const tableColumns = ['Field Id', 'Name', 'Is Required', 'Actions']

interface ICarrierFieldsTable {
    rows: ICarrierField[];
    rowsLoading: boolean;
    onEditAssetField: (id: string) => void;
    onDeleteAssetField: (id: string) => void;
}

const CarrierFieldsTable = ({
    rows,
    rowsLoading,
    onEditAssetField,
    onDeleteAssetField
}: ICarrierFieldsTable) => {

    return (
        <Table
            columns={tableColumns}
            hidePagination={true}>
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </> :
                <>
                    {rows?.length > 0 && rows?.map((row: ICarrierField, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.id}</td>
                                <td className='px-6 py-4'>{row?.name}</td>
                                <td className='px-6 py-4'>{row?.required ? 'Yes' : 'No'}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div onClick={() => row?.id && onEditAssetField(row?.id)}>
                                            <img title={'Edit Asset Field'} alt='edit item' src={'/assets/table-actions/pen.svg'} className={'h-[14px] object-contain cursor-pointer mr-3'} />
                                        </div>
                                        <div onClick={() => row?.id && onDeleteAssetField(row?.id)}>
                                            <img title={'Delete Asset Field'} alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={'h-[14px] object-contain cursor-pointer mr-1'} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default CarrierFieldsTable;