import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IPartnerCarrier } from '../../../../interfaces/partner-carriers/IPartnerCarrier';
import {
    tryToAddPartnerCarrier,
    tryToDeletePartnerCarrier,
    tryToEditPartnerCarrier,
    tryToFetchPartnerCarrierByMarket
} from '../../../../store/brokers/partner/partner-carriers/partnerCarriersSlice';
import { tryToFetchSingleCarrier } from '../../../../store/brokers/admin/carriers/carriersSlice';
import { CARRIER_TYPES } from '../../../../utils/constants/carrier-types';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';
import DeleteModal from '../../../../shared/delete-modal';


interface IManageCarrierFieldsModal {
    openModal: boolean;
    carrierId?: string;
    handleCloseModal: () => void;
    onSuccessfullyFinished: () => void;
}

const ManageCarrierFieldsModal = ({
    openModal,
    carrierId,
    handleCloseModal,
    onSuccessfullyFinished,
}: IManageCarrierFieldsModal) => {
    const dispatch = useAppDispatch();
    const [fieldsErrors, setFieldsErrors] = useState<any>()
    const [fieldsLoading, setFieldsLoading] = useState<boolean>(false)
    const [generalError, setGeneralError] = useState<string>()
    const [carrierFields, setCarrierFields] = useState<any>()
    const [typeSelected, setTypeSelected] = useState<any>()
    const [formData, setFormData] = useState<any>()
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [existingData, setExistingData] = useState<any>()
    const [partnerCarrierId, setPartnerCarrierId] = useState<any>()
    const activeMarket = useAppSelector((state) => state?.partners?.partnerActiveMarket)
    const carrierIsLoading = useAppSelector((state) => state?.carriers?.carrierIsLoading)

    const onGettingDataIfExist = async () => {
        const formatData = {
            carrierId: carrierId || ''
        }
        const response = await dispatch(tryToFetchPartnerCarrierByMarket(formatData)).unwrap()
        setFormData(response ? response?.carrierData : undefined)
        const findTypeByMarket = response && response?.markets && response?.markets?.length > 0 && response?.markets?.find((market: any) => market?.marketId === activeMarket?.market?._id)
        setTypeSelected(findTypeByMarket ? findTypeByMarket?.types : undefined)
        setExistingData(findTypeByMarket ? response || undefined : undefined)
        setPartnerCarrierId(response ? response?._id : undefined)
    }

    const onGettingFields = async () => {
        if (carrierId) {
            const response = await dispatch(tryToFetchSingleCarrier(carrierId || '')).unwrap()
            setCarrierFields(response?.[0])
        }
    }

    useEffect(() => {
        if (carrierId && activeMarket) {
            onGettingDataIfExist()
        }
    }, [activeMarket, carrierId])

    useEffect(() => {
        onGettingFields()
    }, [carrierId])

    useEffect(() => {
        onGettingFields()
    }, [carrierId])

    const onCloseModal = () => {
        setFieldsErrors(undefined)
        setFormData(undefined)
        setExistingData(undefined)
        setTypeSelected(undefined)
        setFieldsLoading(false)
        setGeneralError(undefined)
        handleCloseModal()
    }

    const onSubmit = async () => {
        if (!typeSelected || typeSelected?.length <= 0) {
            setGeneralError('Please select at least one type.')
            return
        }
        let itemErrors: any
        if (carrierFields && carrierFields?.fields && carrierFields?.fields?.length > 0 && formData) {
            carrierFields?.fields?.map((item: any) => {
                if (!formData?.[item?.name] && item?.required) {
                    itemErrors = {
                        ...(itemErrors || {}),
                        [item?.name]: `${item?.name} is required`
                    }
                }
                return item
            })
        } else {
            setGeneralError('Please fill all the fields to continue')
            return;
        }
        if (itemErrors) {
            setFieldsErrors(itemErrors)
            return;
        } else {
            setFieldsErrors(undefined)
        }
        const formatData: IPartnerCarrier = {
            market: activeMarket?.market?._id || '',
            carrierId: carrierId || '',
            types: typeSelected,
            carrierData: {
                ...formData
            }
        }
        try {
            if (existingData) {
                formatData._id = existingData?._id
                formatData.market = {
                    marketId: activeMarket?.market?._id || '',
                    types: typeSelected
                }
                dispatch(tryToEditPartnerCarrier(formatData))
            } else {
                dispatch(tryToAddPartnerCarrier(formatData))
            }
            onSuccessfullyFinished()
            onCloseModal()
        }
        catch (err: any) {
            setGeneralError(err?.response?.message || err?.response || err || 'Something went wrong!')
        }
    }

    const onChangeInput = (value: string | null | undefined, type: string) => {
        setFormData({
            ...(formData || {}),
            [type]: value || undefined
        })
    }

    const onClickType = async (type: string) => {
        const findIfAlreadySelected = typeSelected && typeSelected?.length > 0 && typeSelected?.find((item: string) => item === type)
        if (findIfAlreadySelected) {
            const filterOtherTypes = typeSelected && typeSelected?.length > 0 && typeSelected?.filter((item: string) => item !== type)
            setTypeSelected(filterOtherTypes || [])
        } else {
            setTypeSelected([
                ...typeSelected || [],
                type
            ])
        }
    }

    const onConfirmDelete = async () => {
        if (!partnerCarrierId) {
            setGeneralError('There\'s no data to delete about this carrier')
            return;
        }
        setGeneralError(undefined)
        try {
            await dispatch(tryToDeletePartnerCarrier({ partnerCarrierId: partnerCarrierId || '', marketId: activeMarket?.market?._id || '' })).unwrap()
            setDeleteModal(false)
            setFormData(undefined)
            setTypeSelected(undefined)
        } catch (err) {
            setGeneralError(`${err}`)
        }
        setDeleteModal(false)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            contentContainerStyle={'!min-w-[70vw]'}>
            <div className={'p-2 min-w-[600px] min-h-[350px]'}>
                <div className='flex flex-row items-center justify-between'>
                    <div>
                        <p className='mb-4 font-semibold text-sm'>Set Carrier Details</p>
                        <p className='font-bold text-md mb-3'>Use this form to fill or change carrier details.</p>
                        <p><span className='font-medium text-md'>Market:</span> {activeMarket?.market?.name}</p>
                    </div>
                    <Button
                        label='Delete Carrier For Market'
                        className='btn-main'
                        onClickButton={() => setDeleteModal(true)}
                    />
                </div>
                {generalError && <Error text={generalError} />}
                <div>
                    {fieldsLoading ?
                        <div className='my-6 flex flex-row justify-center items-center min-w-[100%]'>
                            <Loading />
                        </div>
                        : <div className='min-h-[200px] flex flex-col justify-center items-center'>
                            {carrierIsLoading ?
                                <div className='my-6 flex flex-row justify-center items-center min-w-[100%]'>
                                    <Loading />
                                </div>
                                :
                                <>
                                    <p className='min-w-[100%] text-left mt-5 mb-2 font-medium text-md'>Choose one or more types:</p>
                                    <div className='grid grid-cols-5 gap-4 min-w-[100%] mb-5'>
                                        {CARRIER_TYPES?.map((type: any, index: number) => {
                                            const findIfSelected = typeSelected && typeSelected?.length > 0 && typeSelected?.find((item: string) => item === type?.value)
                                            return (
                                                <div key={index} className='flex justify-start items-center'>
                                                    <input
                                                        onChange={() => onClickType(type?.value)}
                                                        checked={!!findIfSelected}
                                                        id='checkbox-all'
                                                        type='checkbox'
                                                        className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2' />
                                                    <span className={'font-md ml-2'}>{type?.label}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 my-5 min-w-[100%]'>
                                        {carrierFields && carrierFields?.fields && carrierFields?.fields?.length > 0 && carrierFields?.fields?.map((field: any, index: number) => {
                                            return (
                                                <Input
                                                    key={index}
                                                    containerStyle='mb-0'
                                                    // placeholder={field?.name}
                                                    label={field?.name}
                                                    showValue={true}
                                                    type={'text'}
                                                    inputValue={formData?.[field?.name]}
                                                    error={fieldsErrors?.[field?.name] || ''}
                                                    onChangeInput={onChangeInput}
                                                    inputUniqueName={field?.name}
                                                />
                                            )
                                        })}
                                    </div>
                                </>
                            }
                        </div>
                    }
                    <div className='min-w-full flex flex-row justify-center my-3'>
                        <Button
                            label={'Save'}
                            className={`${(carrierFields?.fields && carrierFields?.fields?.length > 0) ? 'btn-primary-rounded-less' : 'btn-primary-rounded-less-disable'} min-w-[130px] !shadow-none`}
                            onClickButton={() => (carrierFields?.fields && carrierFields?.fields?.length > 0) && onSubmit()}
                        />
                    </div>
                </div>
                <DeleteModal
                    openDeleteModal={deleteModal}
                    handleCloseDeleteModal={() => setDeleteModal(false)}
                    onApproveDeletion={onConfirmDelete}
                />
            </div>
        </Modal>
    )
}

export default ManageCarrierFieldsModal;