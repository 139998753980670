import { AxiosResponse } from 'axios';
import { ASSETS_API_URL, CARRIERS_API_URL } from '../../../../config';
import carriersAxios from '../../../../utils/axios/carriers.axios';
import assetsAxios from '../../../../utils/axios/assets.axios';
import { PaginatedLabelPacks } from '../../../../interfaces/label-packs/paginatedStore.type';


const tryToFetchAllCompleteAssetsTemplates = (): Promise<AxiosResponse<any>> => {
    return assetsAxios.get<any>(`${ASSETS_API_URL}/dom/asset-templates/complete`);
};

const tryToFetchAllLabelPacksFiltered = (
    pageNumber: number,
    pageSize: number,
    data?: any
): Promise<AxiosResponse<PaginatedLabelPacks>> => {
    const url = `${CARRIERS_API_URL}/label-packs/filtered?page=${pageNumber}&size=${pageSize}`
    return carriersAxios.put<PaginatedLabelPacks>(url, data);
};

const tryToFetchLabelPacksFiltered = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${CARRIERS_API_URL}/label-packs/filtered/all`
    return carriersAxios.put<any>(url, data);
};

const tryToChangeLabelPackStatus = (status: string, data?: any): Promise<AxiosResponse> => {
    const url = `${CARRIERS_API_URL}/label-packs/status/${status}`
    return carriersAxios.put<any>(url, data);
};

const tryToPrintAllLabelPack = (data?: any): Promise<AxiosResponse> => {
    const url = `${CARRIERS_API_URL}/label-packs/print`
    return carriersAxios.put<any>(url, data);
};

export const LabelPacksService = {
    tryToFetchAllCompleteAssetsTemplates,
    tryToFetchAllLabelPacksFiltered,
    tryToPrintAllLabelPack,
    tryToFetchLabelPacksFiltered,
    tryToChangeLabelPackStatus
}