import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IActiveMarket } from '../../../interfaces/markets/IActiveMarket';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import {
    tryToFetchAllLabelPacksFiltered,
    tryToChangeLabelPackStatus,
    tryToPrintAllLabelPack
} from '../../../store/brokers/admin/label-packs/labelPacksSlice';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-template/assetsTemplatesSlice';
import LabelPacksFilters from './details/LabelPacksFilters';
import LabelsActionModal from './modals/LabelsActionModal';
import ActionCheckModal from './modals/ActionCheckModal';
import LabelPacksTable from './tables/LabelPacksTable';
import SearchWithButton from '../../../shared/search-with-button';
import Button from '../../../shared/button';


interface IFilters {
    sellerId?: string | undefined;
    sellerName?: string | undefined;
    itemName?: string | undefined;
    type?: 'instant' | 'regular' | '';
    status?: 'created' | 'completed';
    category?: any;
}

const LabelPacks = () => {
    const dispatch = useAppDispatch();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [rows, setRows] = useState<any[]>([]);
    const [activeMarket, setActiveMarket] = useState<IActiveMarket>();
    const [filters, setFilters] = useState<IFilters>({})
    const [selectedType, setSelectedType] = useState<any>()
    const [status, setStatus] = useState<'created' | 'completed' | ''>('')
    const [search, setSearch] = useState<string>()
    const [selectedRows, setSelectedRows] = useState<Array<string>>()
    const [loadingPrint, setLoadingPrint] = useState<boolean>(false)
    const [labelsActionModal, setLabelsActionModal] = useState<{
        show: boolean;
        text: string;
        title: string;
        buttonText: string;
    }>()
    const [showActionCheckModal, setShowActionCheckModal] = useState<{
        show: boolean;
        text: string;
        title: string;
        type: string;
        error: boolean;
    }>();
    const state = useAppSelector(state => state?.labelPacks.labelPacks)
    const categories = useAppSelector((state) => state.assetsTemplates?.allAssetTemplates);
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket);
    const paginationData = useAppSelector((state) => state?.labelPacks?.labelPacks?.data.page);
    const rowsLoading = useAppSelector((state) => state?.labelPacks?.labelPacksAreLoading);

    useEffect(() => {
        if (state) {
            const rows =
                state?.data?.elements &&
                    Array.isArray(state?.data.elements)
                    ? state?.data.elements.map((r) => ({ ...r }))
                    : [];
            setRows(rows);
        }
    }, [state]);

    const getLabelPacksByCategoryAndFilters = async () => {
        const payloadFilters: any = filters
        for (const key in payloadFilters) {
            if (payloadFilters[key] === '') {
                delete payloadFilters[key];
            }
        }
        const data = {
            ...payloadFilters
        }
        await dispatch(tryToFetchAllLabelPacksFiltered({ ...paginationState, data })).unwrap()
    }

    const getAllCategories = async () => {
        await dispatch(tryToFetchAllAssetsTemplates()).unwrap();
    };

    useEffect(() => {
        getAllCategories();
        getLabelPacksByCategoryAndFilters()
    }, []);

    useEffect(() => {
        if (
            activeMarketState &&
            JSON.stringify(activeMarketState) !== JSON.stringify(activeMarket)
        ) {
            setActiveMarket(activeMarketState || undefined);
        }
    }, [activeMarketState, activeMarket]);

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        });
    };

    const onClickSelectRow = (id?: string) => {
        if (!id) {
            if (selectedRows?.length === rows?.length) {
                setSelectedRows(undefined)
            } else {
                const getAllIds = rows?.length > 0 && rows?.map((row) => row?._id || '')
                setSelectedRows(getAllIds || [])
            }
        }
        else {
            const findIfAlreadySelected = selectedRows && selectedRows?.length > 0 && selectedRows?.find((row) => row === id)
            if (findIfAlreadySelected) {
                const filterOthers = selectedRows && selectedRows?.length > 0 && selectedRows?.filter((row) => row !== id)
                setSelectedRows(filterOthers || undefined)
            } else {
                const formatNew = [
                    ...selectedRows || [],
                    id || ''
                ]
                setSelectedRows(formatNew || undefined)
            }
        }
    }

    const onSelectCategory = (category: any) => {
        setSelectedCategory(category);
        setFilters({
            ...filters,
            category: category.name,
        })
        onResetPaginationState();
    };

    const onChangeUserId = (value: string | null | undefined, type: string) => {
        setFilters({
            ...filters,
            sellerId: value || '',
        })
        onResetPaginationState();
    }

    const onChangeUserName = (value: string | null | undefined, type: string) => {
        setFilters({
            ...filters,
            sellerName: value || '',
        })
        onResetPaginationState();
    }
    const onChangeItemName = (value: string | null | undefined, type: string) => {
        setFilters({
            ...filters,
            itemName: value || '',
        })
        onResetPaginationState();
    }
    const onChangeSelectType = (type: any) => {
        setSelectedType(type)
        setFilters({
            ...filters,
            type: type.value,
        })
        onResetPaginationState();
    }

    const onClickChangeStatus = (value: 'created' | 'completed') => {
        setStatus(value)
        setFilters({
            ...filters,
            status: value,
        })
    }
    useEffect(() => {
        getLabelPacksByCategoryAndFilters();
    }, [filters, selectedCategory, paginationState])

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    const clearAllFilters = () => {
        setFilters({})
        setSelectedType(undefined)
        setStatus('')
        setSearch(undefined)
        setSelectedCategory(undefined)
        onResetPaginationState()
    }

    const onClickCompleteAll = async () => {
        // if(loadingComplete){
        //     return
        // }
        // try {
        //     const data = {
        //         newStatus: 'completed',
        //         labelIds: {labelIds: [...rows.map(row => row._id)]}
        //     }
        //     await dispatch(tryToChangeLabelPackStatus(data)).unwrap()
        //     getLabelPacksByCategoryAndFilters();
        //     setLoadingComplete(true)
        //     setShowToast({
        //         type: 'success',
        //         message: `Order Item was accepted successfully.`,
        //     });
        //     onResetPaginationState()
        // } catch (err) {
        //     setShowToast({ type: 'error', message: `${err}` });

        // }
        // setLoadingComplete(false)
        // setShowActionCheckModal(undefined)
    }

    const onClickLabelsAction = async () => {
        setLabelsActionModal(undefined)
        onResetPaginationState()
        getLabelPacksByCategoryAndFilters();
    }

    const onClickChangeLabelPackStatus = async (newStatus: string, id: string) => {
        const data = {
            newStatus,
            labelIds: { labelIds: [id] },
        }

        await dispatch(tryToChangeLabelPackStatus(data)).unwrap()
        getLabelPacksByCategoryAndFilters();
    }

    const onPrintAllLabels = async (e: any) => {
        setLoadingPrint(true)
        const data = {
            ...filters
        }
        const response = await dispatch(tryToPrintAllLabelPack(data)).unwrap()
        window.open(response?.url, '_blank')
        setLoadingPrint(false)
    };

    const onToggleLabelsActionModal: any = () => {
        setLabelsActionModal({
            show: true,
            text: 'Select a category to mark all label packs as completed.',
            title: 'Confirm All Label Packs',
            buttonText: 'Complete All'
        });
    };

    const onPrintSelectedLabels = async () => {
        setLoadingPrint(true)
        const data = {
            items: selectedRows
        }
        const response = await dispatch(tryToPrintAllLabelPack(data)).unwrap()
        window.open(response?.url, '_blank')
        setSelectedRows(undefined)
        setLoadingPrint(false)
    }

    return (
        <div>
            <div className='mb-8'>
                <p className='page-title'>Label Packs</p>
            </div>
            <div className='ml-2'>
                <div>
                    <LabelPacksFilters
                        onChangeUserId={onChangeUserId}
                        onChangeUserName={onChangeUserName}
                        onChangeItemName={onChangeItemName}
                        onChangeSelectType={onChangeSelectType}
                        selectedType={selectedType}
                        filters={filters}
                        categories={categories?.map((category: IAssetTemplate) => {
                            const categoryWithLabelAndValue =
                            {
                                ...category,
                                label: category.displayName,
                                value: category.name
                            }
                            return categoryWithLabelAndValue
                        })}
                        onSelectCategory={onSelectCategory}
                        selectedCategory={selectedCategory}
                    />
                </div>
                <div className='flex flex-col md:flex-row justify-start md:justify-between items-center md:items-end mb-2'>
                    <div className='max-w-fit mb-2 grid grid-cols-2 gap-4'>
                        <Button
                            label={'Created'}
                            className={`${status === 'created' ? 'btn-categories' : 'btn-noactive-categories border'} !min-w-[70px]`}
                            onClickButton={() => onClickChangeStatus('created')}
                        />
                        <Button
                            label={'Completed'}
                            className={`${status === 'completed' ? 'btn-categories' : 'btn-noactive-categories border'} !min-w-[70px]`}
                            onClickButton={() => onClickChangeStatus('completed')}
                        />
                    </div>
                    <div className='sm:w-full md:max-w-fit mb-2 flex flex-row justify-center md:justify-end'>
                        <Button
                            label={'Print All'}
                            className={`min-w-[150px] ${loadingPrint ? 'btn-main-disable' : 'btn-main'} !py-2 px-3 !shadow-none mr-3`}
                            onClickButton={(e) => onPrintAllLabels(e)}
                        />
                        <Button
                            label={'Complete All'}
                            className={'min-w-[150px] btn-main !py-2 px-3 !shadow-none'}
                            onClickButton={onToggleLabelsActionModal}
                        />
                    </div>
                </div>
                <div>
                    <SearchWithButton
                        placeholder='Filter Table'
                        buttonLabel='Clear Filters'
                        buttonStyle='btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end'
                        onChangeSearchValue={onChangeSearchValue}
                        search={search}
                        inputStyle={'bg-transparent'}
                        onClickButton={clearAllFilters}
                        mainContainerStyle={'!bg-white !grid-cols-10'}
                    />
                </div>
                {(selectedRows && selectedRows?.length > 0) &&
                    <div className='min-w-full p-4 flex flex-row justify-between items-center bg-[#F1F9F7]'>
                        <p className='font-bold'>{selectedRows ? selectedRows?.length : 0} Rows selected</p>
                        <Button
                            label={'Print Labels'}
                            onClickButton={() => !loadingPrint && onPrintSelectedLabels()}
                            className={'btn-primary-rounded-less flex flex-row align-start my-2 !py-2'}
                        />
                    </div>
                }
                <div>
                    <LabelPacksTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        selectedRows={selectedRows}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                        onClickSelectRow={onClickSelectRow}
                        onClickChangeLabelPackStatus={onClickChangeLabelPackStatus}
                    />
                </div>
                <LabelsActionModal
                    openLabelsActionModal={labelsActionModal?.show || false}
                    text={labelsActionModal?.text || ''}
                    buttonText={labelsActionModal?.buttonText || ''}
                    title={labelsActionModal?.title || ''}
                    handleCloseModal={() => setLabelsActionModal(undefined)}
                    onApproveAction={onClickLabelsAction}
                />
                <ActionCheckModal
                    openActionCheckModal={showActionCheckModal?.show || false}
                    handleCloseModal={() => setShowActionCheckModal(undefined)}
                    text={showActionCheckModal?.text || ''}
                    title={showActionCheckModal?.title || ''}
                    errorType={showActionCheckModal?.error || false}
                    action={showActionCheckModal?.type || ''}
                    onApproveAction={onClickCompleteAll}
                />
            </div>
        </div>
    );
};
export default LabelPacks;
