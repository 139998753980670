import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LabelPacksService } from './labelPacksApi';
import { ILabelPacksListState } from '../../../../interfaces/label-packs/ILabelPacks';


const initialState: ILabelPacksListState = {
    labelPacksAreLoading: false,
    toastSuccessRequest: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchAllCompleteAssetsTemplates = createAsyncThunk<any>(
    'labelPacks/tryToFetchAllCompleteAssetsTemplates',
    async () => {
        const result = await LabelPacksService.tryToFetchAllCompleteAssetsTemplates();
        return result?.data?.data;
    },
);

export const tryToFetchAllLabelPacksFiltered = createAsyncThunk<any, { pageNumber: number, pageSize: number, data?: any }>(
    'labelPacks/tryToFetchAllLabelPacksFiltered', async ({ pageNumber, pageSize, data }) => {
        const result = await LabelPacksService.tryToFetchAllLabelPacksFiltered(
            pageNumber,
            pageSize,
            data
        );
        return result?.data;
    }
)

export const tryToFetchLabelPacksFiltered = createAsyncThunk<any, { data?: any }>(
    'labelPacks/tryToFetchLabelPacksFiltered', async ({ data }) => {
        const result = await LabelPacksService.tryToFetchLabelPacksFiltered(
            data
        );
        return result?.data;
    }
)

export const tryToChangeLabelPackStatus = createAsyncThunk<any, any>(
    'labelPacks/tryToChangeLabelPackStatus', async (data) => {
        const result = await LabelPacksService.tryToChangeLabelPackStatus(
            data.newStatus, data.labelIds
        );
        return result?.data;
    }
)

export const tryToPrintAllLabelPack = createAsyncThunk<any, any>(
    'labelPacks/tryToPrintAllLabelPack', async (data) => {
        const result = await LabelPacksService.tryToPrintAllLabelPack(
            data
        );
        return result?.data;
    }
)

export const labelPacksSlice = createSlice({
    name: 'labelPacks',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchAllLabelPacksFiltered
            .addCase(tryToFetchAllLabelPacksFiltered.pending, (state) => {
                state.labelPacksAreLoading = true;
            })
            .addCase(tryToFetchAllLabelPacksFiltered.rejected, (state) => {
                state.labelPacksAreLoading = false;
            })
            .addCase(tryToFetchAllLabelPacksFiltered.fulfilled, (state, action) => {
                state.labelPacksAreLoading = false;
                state.labelPacks = action?.payload;
            })

            // tryToFetchLabelPacksFiltered
            .addCase(tryToFetchLabelPacksFiltered.pending, (state) => {
                state.labelPacksAreLoading = true;
            })
            .addCase(tryToFetchLabelPacksFiltered.rejected, (state) => {
                state.labelPacksAreLoading = false;
            })
            .addCase(tryToFetchLabelPacksFiltered.fulfilled, (state, action) => {
                state.labelPacksAreLoading = false;
                state.labelPacksNotPaginated = action?.payload
            })
    },
});
export default labelPacksSlice.reducer;
