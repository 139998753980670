import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IPartnerCarrier } from '../../../../interfaces/partner-carriers/IPartnerCarrier';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IPartnerCarrierSchema } from '../../../../interfaces/partner-carriers/IPartnerCarrierSchema';
import { PaginatedPartnerCarriers } from '../../../../interfaces/partner-carriers/paginatedStore.type';
import { IPartnerCarriersListState } from '../../../../interfaces/partner-carriers/IPartnerCarrierList';
import { IPaginationPayload, IPaginationPayloadForMarketId } from '../../../../interfaces/shared/IPaginationPayload';
import { PartnerCarriersService } from './partnerCarriersApi';


const initialState: IPartnerCarriersListState = {
    partnerCarrierIsLoading: false,
    partnerCarriersAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchPartnerCarriers = createAsyncThunk<
    PaginatedPartnerCarriers,
    IPaginationPayload
>('partnerCarriers/tryToFetchPartnerCarriers', async ({ pageNumber, pageSize, filters }) => {
    const result = await PartnerCarriersService.tryToFetchPartnerCarriers(
        pageNumber,
        pageSize,
        filters,
    );
    return result?.data;
});

export const tryToFetchPartnerCarrierByMarket = createAsyncThunk<any, { carrierId: string }>(
    'partnerCarriers/tryToFetchPartnerCarrierByMarket',
    async ({ carrierId }) => {
        const formData = {
            carrierId
        }
        const result = await PartnerCarriersService.tryToFetchPartnerCarrierByMarket(formData);
        return result?.data;
    },
);

// Paginated
export const tryToFetchAllPartnerCarriers = createAsyncThunk<
    PaginatedPartnerCarriers,
    IPaginationPayloadForMarketId
>('partner-carriers/tryToFetchAllPartnerCarriers', async ({ pageNumber, pageSize, marketId, type }) => {
    const result = await PartnerCarriersService.tryToFetchAllPartnerCarriers(
        pageNumber,
        pageSize,
        marketId,
        type,
    );
    return result?.data;
});

export const tryToFetchAllPartnerCarriersByMarket = createAsyncThunk<any, any>(
    'partner-carriers/tryToFetchAllPartnerCarriersByMarket',
    async (data: any) => {
        const result = await PartnerCarriersService.tryToFetchAllPartnerCarriersByMarket(data);
        return result.data;
    }
);

export const tryToAddPartnerCarrier = createAsyncThunk<ApiResponse<IPartnerCarrierSchema>, IPartnerCarrier>(
    'partnerCarriers/tryToAddPartnerCarrier',
    async (data: IPartnerCarrierSchema, { rejectWithValue }) => {
        try {
            const result = await PartnerCarriersService.tryToAddPartnerCarrier(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditPartnerCarrier = createAsyncThunk<ApiResponse<IPartnerCarrier>, any>(
    'partnerCarriers/tryToEditPartnerCarrier', async (data: any, { rejectWithValue }) => {
        try {
            const result = await PartnerCarriersService.tryToEditPartnerCarrier(
                data._id || '',
                data,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToDeletePartnerCarrier = createAsyncThunk<ApiResponse<IPartnerCarrier>, any>(
    'partnerCarriers/tryToDeletePartnerCarrier', async ({ partnerCarrierId, marketId }, { rejectWithValue }) => {
        try {
            const result = await PartnerCarriersService.tryToDeletePartnerCarrier(partnerCarrierId, marketId);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const partnerCarriersSlice = createSlice({
    name: 'partnerCarriers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchPartnerCarriers
            .addCase(tryToFetchPartnerCarriers.pending, (state) => {
                state.partnerCarriersAreLoading = true;
            })
            .addCase(tryToFetchPartnerCarriers.rejected, (state) => {
                state.partnerCarriersAreLoading = false;
            })
            .addCase(tryToFetchPartnerCarriers.fulfilled, (state, action) => {
                state.partnerCarriersAreLoading = false;
                state.partnerCarriers = action.payload;
            })

            // tryToFetchPartnerCarrierByMarket
            .addCase(tryToFetchPartnerCarrierByMarket.pending, (state) => {
                state.partnerCarrierIsLoading = true;
            })
            .addCase(tryToFetchPartnerCarrierByMarket.rejected, (state) => {
                state.partnerCarrierIsLoading = false;
            })
            .addCase(tryToFetchPartnerCarrierByMarket.fulfilled, (state, action) => {
                state.partnerCarrierIsLoading = false;
                state.partnerCarriers = action.payload;
            })

            // tryToFetchAllPartnerCarrier
            .addCase(tryToFetchAllPartnerCarriers.pending, (state) => {
                state.partnerCarrierIsLoading = true;
            })
            .addCase(tryToFetchAllPartnerCarriers.rejected, (state) => {
                state.partnerCarrierIsLoading = false;
            })
            .addCase(tryToFetchAllPartnerCarriers.fulfilled, (state, action) => {
                state.partnerCarrierIsLoading = false;
                state.partnerCarriers = action.payload;
            })

            // tryToAddPartnerCarrier
            .addCase(tryToAddPartnerCarrier.pending, (state) => {
                state.partnerCarrierIsLoading = true;
            })
            .addCase(tryToAddPartnerCarrier.rejected, (state) => {
                state.partnerCarrierIsLoading = false;
            })
            .addCase(tryToAddPartnerCarrier.fulfilled, (state, action) => {
                state.partnerCarrierIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.partnerCarriers?.data)
                    state.partnerCarriers.data.elements = [
                        ...state.partnerCarriers?.data.elements,
                        data,
                    ];
            })

            // tryToEditPartnerCarrier
            .addCase(tryToEditPartnerCarrier.pending, (state) => {
                state.partnerCarrierIsLoading = true;
            })
            .addCase(tryToEditPartnerCarrier.rejected, (state) => {
                state.partnerCarrierIsLoading = false;
            })
            .addCase(tryToEditPartnerCarrier.fulfilled, (state, action) => {
                state.partnerCarrierIsLoading = false;
                if (state.partnerCarriers?.data.elements)
                    state.partnerCarriers.data.elements =
                        { ...state }.partnerCarriers?.data.elements.map((carrier) => {
                            if (carrier?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return carrier;
                            }
                        }) || [];
                state.partnerCarrierDetail = action.payload.data;
            })

            // tryToDeletePartnerCarrier
            .addCase(tryToDeletePartnerCarrier.fulfilled, (state, action) => {
                state.partnerCarrierIsLoading = false;
                state.partnerCarrierDetail = undefined;
            })
    },
});

export default partnerCarriersSlice.reducer;