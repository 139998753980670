import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  tryToAddCarrierService,
  tryToEditCarrierService,
} from '../../../../store/brokers/admin/carriers/carriersServiceSlice';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';

interface ICreateCarrierModal {
  openCreateCarrierServiceModal: boolean;
  carrierServiceId?: string | null;
  selectedRow?: any;
  serviceId?: any;
  onCloseCarrierServiceModal: () => void;
  onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
  name: string;
  description: string;
};

const CarrierServiceModal = ({
  openCreateCarrierServiceModal,
  onCloseCarrierServiceModal,
  selectedRow,
  carrierServiceId,
  serviceId,
  onSaveChanges,
}: ICreateCarrierModal) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.carrierServices?.carrierServices);
  const carrierServicesLoading = useAppSelector((state) => state?.carrierServices?.carrierServiceIsLoading);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormValues>({});

  useEffect(() => {
    if (serviceId) {
      setValue('name', serviceId ? selectedRow?.name || '' : '');
      setValue('description', serviceId ? selectedRow?.description || '' : '');
    }
  }, [state?.carrierServices, serviceId]);

  const onSubmit = async (data: FormValues) => {
    try {
      if (serviceId) {
        const payload = {
          name: data.name,
          description: data.description,
          carrierId: carrierServiceId,
          serviceId
        };
        await dispatch(tryToEditCarrierService(payload)).unwrap();
      } else if (carrierServiceId) {
        const payload = {
          name: data.name,
          description: data.description,
          carrierId: carrierServiceId,
        }
        await dispatch(tryToAddCarrierService(payload)).unwrap();
      }
      onSaveChanges('success', `Service successfully ${serviceId ? 'changed' : 'added'}.`);
    } catch (error) {
      onSaveChanges('error', `${error}`);
    }
    reset();
    onCloseCarrierServiceModal();
  };

  const onCloseModal = () => {
    reset();
    onCloseCarrierServiceModal();
  };

  return (
    <Modal open={openCreateCarrierServiceModal} onClose={onCloseModal}>
      <div className={'p-2 min-w-[500px]'}>
        <p className="mt-2 mb-4">{serviceId ? 'Edit Carrier Service' : 'Add Carrier Service'}</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          {((serviceId && !carrierServicesLoading) || !serviceId) ?
            <div className="flex flex-col my-4">
              <Input
                placeholder="Name"
                label={serviceId ? 'Name' : ''}
                register={register('name', {
                  required: {
                    message: 'Name is required',
                    value: true,
                  },
                  validate: (value: string) =>
                    !!value.trim() || 'Name is required',
                })}
                error={errors.name?.message}
              />
              <Input
                placeholder="Description"
                label={serviceId ? 'Description' : ''}
                register={register('description', {
                  required: {
                    message: 'Description is required',
                    value: true,
                  },
                  validate: (value: string) =>
                    !!value.trim() || 'Description is required',
                })}
                error={errors.description?.message}
              />

              <div className="flex flex-row justify-end mt-4 gap-2 ">
                <Button label="Save" type={'submit'} className={'btn-primary'} />
              </div>
            </div>
            : <div className='my-3 flex flex-row justify-center items-center'>
              <Loading />
            </div>
          }
        </form>
      </div>
    </Modal>
  );
};
export default CarrierServiceModal;
