import React from 'react';
import Button from '../button';
import Input from '../input';

interface ISearchWithButton {
    placeholder: string;
    search?: string;
    buttonLabel: string;
    mainContainerStyle?: string,
    buttonStyle?: string;
    inputStyle?: string;
    showScndBtn?: boolean;
    scndButtonLabel?: string;
    thirdButtonLabel?: string;
    showThirdBtn?: boolean;
    onClickSecondButton?: () => void;
    onClickThirdButton?: () => void;
    scndButtonStyle?: string;
    onClickButton?: () => void;
    onChangeSearchValue: (value: string | null | undefined, type: string) => void;
}

const SearchWithButton = ({
    search,
    buttonLabel,
    placeholder,
    mainContainerStyle,
    buttonStyle,
    inputStyle,
    showScndBtn,
    scndButtonLabel,
    scndButtonStyle,
    showThirdBtn,
    thirdButtonLabel,
    onClickThirdButton,
    onClickSecondButton,
    onChangeSearchValue,
    onClickButton
}: ISearchWithButton) => {
    return (
        <div className={`grid grid-cols-9 bg-white ${mainContainerStyle}`}>
            <div className={`col-span-8 ${showThirdBtn ? 'col-span-4' : showScndBtn ? 'col-span-6' : 'col-span-8 '}`}>
                <Input
                    placeholder={placeholder}
                    containerStyle='mb-0 pb-0 relative'
                    inputStyle={`mb-0 border-0 focus:outline-none ${inputStyle || ''}`}
                    icon={'/assets/shared/search.svg'}
                    showValue={true}
                    inputValue={search}
                    inputUniqueName={'search'}
                    onChangeInput={onChangeSearchValue}
                    hideInputSpace={true}
                />
            </div>
            {showThirdBtn &&
                <Button
                    label={thirdButtonLabel || ''}
                    onClickButton={onClickThirdButton}
                    className={`!shadow-none min-w-full pb-2 ${scndButtonStyle}`}

                />}
            {showScndBtn &&
                <Button
                    label={scndButtonLabel || ''}
                    onClickButton={onClickSecondButton}
                    className={`!shadow-none min-w-full pb-2 ${scndButtonStyle}`}

                />}
            <Button
                label={buttonLabel}
                onClickButton={onClickButton}
                className={`!shadow-none min-w-full pb-2 ${buttonStyle}`}
            />

        </div>
    )
}

export default SearchWithButton;