import { AxiosResponse } from 'axios';
import { CARRIERS_API_URL } from '../../../../config';
import { IPartnerCarrier } from '../../../../interfaces/partner-carriers/IPartnerCarrier';
import { PaginatedPartnerCarriers } from '../../../../interfaces/partner-carriers/paginatedStore.type';
import carriersAxios from '../../../../utils/axios/carriers.axios';

const tryToFetchPartnerCarriers = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
): Promise<AxiosResponse<PaginatedPartnerCarriers>> => {
    return carriersAxios.put(`${CARRIERS_API_URL}/partner-carriers?page=${pageNumber}&size=${pageSize}`);
};

const tryToFetchPartnerCarrierByMarket = (data: any): Promise<AxiosResponse> => {
    return carriersAxios.put(`${CARRIERS_API_URL}/partner-carriers/partner-carrier`, data);
}

// Paginated
const tryToFetchAllPartnerCarriers = (
    pageNumber: number,
    pageSize: number,
    marketId?: string,
    type?: string
): Promise<AxiosResponse<PaginatedPartnerCarriers>> => {
    const url = `${CARRIERS_API_URL}/partner-carriers/all?page=${pageNumber}&size=${pageSize}`
    return carriersAxios.put<PaginatedPartnerCarriers>(url, { marketId, type });
};

const tryToFetchAllPartnerCarriersByMarket = (data: any): Promise<AxiosResponse> => {
    return carriersAxios.put(`${CARRIERS_API_URL}/partner-carriers/all`, data);
};

const tryToAddPartnerCarrier = (data: IPartnerCarrier): Promise<AxiosResponse> => {
    return carriersAxios.post(`${CARRIERS_API_URL}/partner-carriers`, data);
};

const tryToEditPartnerCarrier = (partnerCarrierId: any, data: IPartnerCarrier): Promise<AxiosResponse> => {
    return carriersAxios.put(`${CARRIERS_API_URL}/partner-carriers/${partnerCarrierId}`, data);
};

const tryToDeletePartnerCarrier = (partnerCarrierId: any, marketId: string): Promise<AxiosResponse> => {
    return carriersAxios.put(`${CARRIERS_API_URL}/partner-carriers/${partnerCarrierId}/market/${marketId}/remove`);
};

export const PartnerCarriersService = {
    tryToAddPartnerCarrier,
    tryToFetchPartnerCarrierByMarket,
    tryToEditPartnerCarrier,
    tryToFetchPartnerCarriers,
    tryToFetchAllPartnerCarriers,
    tryToFetchAllPartnerCarriersByMarket,
    tryToDeletePartnerCarrier
};
