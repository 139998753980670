import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  tryToDeleteCarrierService,
  tryToEditCarrierService,
  tryToFetchCarrierServices
} from '../../../store/brokers/admin/carriers/carriersServiceSlice';
import { IToast } from '../../../interfaces/components/IToast';
import CarrierServiceModal from '../carriers/modals/CarrierServiceModal';
import CarriersServiceTable from './table/CarrierServicesTable';
import EmptyContainer from '../../../shared/empty-container';
import DeleteModal from '../../../shared/delete-modal';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const CarrierServices = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [serviceId, setServiceId] = useState<any>()
  const [showServiceModal, setShowServiceModal] = useState<boolean>(false);
  const [showDeleteCarrierServiceModal, setShowDeleteCarrierServiceModal] = useState<boolean>(false)
  const [selectedRow, setSelectedRow] = useState<any>(false);
  const [rows, setRows] = useState<any>();
  const [showToast, setShowToast] = useState<IToast | null>()
  const state = useAppSelector((state) => state?.carrierServices);
  const rowsLoading = useAppSelector((state) => state.carrierServices?.carrierServicesAreLoading);

  useEffect(() => {
    if (state?.carrierServiceDetails) {
      setRows(state?.carrierServiceDetails)
    }
  }, [state?.carrierServiceDetails])

  const getAllServices = async () => {
    await dispatch(tryToFetchCarrierServices(id || '')).unwrap();
  };

  useEffect(() => {
    getAllServices();
  }, []);

  const onSaveChanges = async (type: string, message: string) => {
    setShowToast({ type, message });
    setServiceId(null)
    getAllServices();
  };

  const onAddNewService = (id?: string) => {
    if (id) {
      setServiceId(id)
    }
    setShowServiceModal(true)
  }

  const onCloseCarrierServiceModal = () => {
    setServiceId(undefined)
    setShowServiceModal(false);
  };

  const onEditCarrierServiceDetails = (row?: any, carrierId?: string, serviceId?: string) => {
    setSelectedRow(row)
    setServiceId(serviceId)
    setShowServiceModal(true)
  }

  const onDeleteCarrierService = (carrierId: string, serviceId: string) => {
    setServiceId(serviceId)
    setShowDeleteCarrierServiceModal(true)
  }

  const onDisableCarrierService = async () => {
    try {
      if (id && serviceId) {
        await dispatch(tryToDeleteCarrierService({ carrierId: id, serviceId })).unwrap();
        setShowToast({ type: 'success', message: 'Item successfully deleted' });
      }
    } catch (error) {
      setShowToast({ type: 'error', message: `${error}` });
    }
    setServiceId(null)
    setShowDeleteCarrierServiceModal(false)
  }

  const onCloseDeleteModal = () => {
    setServiceId(null)
    setShowDeleteCarrierServiceModal(false)
  }

  const onEnableDisableFeatures = async (id: string, serviceId: any, type: string, status: string[]) => {
    try {
      let statusPayload = [...status]
      if (status?.includes(type)) {
        statusPayload = status?.filter(el => el !== type)
      } else {
        statusPayload?.push(type)
      }
      await dispatch(tryToEditCarrierService({ carrierId: id, serviceId, statusPayload })).unwrap();
      setShowToast({ type: 'success', message: `Carrier Service ${type} ${status.includes(type) ? 'disabled' : 'enabled'} successfully` });
    } catch (error) {
      setShowToast({ type: 'error', message: `${error}` });
    }
  }

  const onGoBack = () => {
    navigate('/carriers');
  };

  return (
    <div>
      <div className="mb-5">
        <div className='flex flex-row items-center'>
          <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
            <img alt='back' src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
          </div>
          <p className="page-title">
            Carrier -{' '}
            {rows?.displayName}
          </p>
        </div>
      </div>
      <div className="flex flex-row justify-end">
        <Button
          label={'Add New Service'}
          className={'btn-main'}
          onClickButton={onAddNewService}
        />
      </div>
      <div className="my-5 relativ">
        {(rows || rowsLoading) ?
          <CarriersServiceTable
            rows={rows}
            setSelectedRow={setSelectedRow}
            onDeleteCarrierService={onDeleteCarrierService}
            onEditCarrierServiceDetails={onEditCarrierServiceDetails}
            onEnableDisableFeatures={onEnableDisableFeatures}
          />
          : <EmptyContainer
            text={'No Carrier Services to view yet.'}
            showImage={true}
          />
        }
      </div>
      {showToast?.message &&
        <Toast
          type={showToast?.type}
          text={showToast?.message}
          onHandleCloseToast={() => setShowToast(null)}
        />
      }
      {showServiceModal &&
        <CarrierServiceModal
          onCloseCarrierServiceModal={onCloseCarrierServiceModal}
          openCreateCarrierServiceModal={showServiceModal}
          selectedRow={selectedRow}
          carrierServiceId={id || ''}
          serviceId={serviceId}
          onSaveChanges={onSaveChanges}
        />
      }
      <DeleteModal
        openDeleteModal={showDeleteCarrierServiceModal}
        handleCloseDeleteModal={onCloseDeleteModal}
        onApproveDeletion={onDisableCarrierService}
      />
    </div>
  );
};

export default CarrierServices;
