import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { USERS_API_URL } from '../../config';
import { removeFromStorage } from '../webStorage';

const axiosConfig: AxiosRequestConfig = {
    baseURL: `${USERS_API_URL}` || 'http://localhost:3000/',
};
const authAxios = axios.create(axiosConfig);

const logout = () => {
    removeFromStorage('accessToken');
    removeFromStorage('refreshToken');
    window.location.reload();

};

// Add a response interceptor
authAxios.interceptors.response.use(
    (response) => {
        if (response?.data?.error === 'No permission!') logout();
        return response;
    },
    async (error) => {
        const err = { ...error };
        return Promise.reject(err);
    },
);

export const authAxiosQuery =
    (): BaseQueryFn<{
        url: string;
        method: AxiosRequestConfig['method'];
        data?: AxiosRequestConfig['data'];
        headers?: AxiosRequestConfig['headers'];
    }> =>
        async ({ url, method, data, headers }) => {
            try {
                const result = await authAxios({ url, method, data, headers });
                return { data: result.data };
            } catch (axiosError) {
                const err = axiosError as AxiosError;
                return {
                    error: { status: err.response?.status, data: err.response?.data },
                };
            }
        };

export default authAxios;
