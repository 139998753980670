import { AxiosResponse } from 'axios';
import { CARRIERS_API_URL } from '../../../../config';
// import { PaginatedPartners } from '../../../../interfaces/partners/paginatedStore.type';
import carriersAxios from '../../../../utils/axios/carriers.axios';

// Paginated
const tryToFetchCarrierServices = (
  carrierId: string
): Promise<AxiosResponse> => {
  const url = `${CARRIERS_API_URL}/carriers/${carrierId}`;
  const response = carriersAxios.get<string>(`${url}`);
  return response;
};

const tryToAddCarrierService = (
  data: any,
  carrierId: string
): Promise<AxiosResponse> => {
  return carriersAxios.put(
    `${CARRIERS_API_URL}/carriers/${carrierId}/services/add`,
    data
  );
};

const tryToEditCarrierService = (
  carrierId: string,
  serviceId: string,
  name?: string,
  description?: string,
  status?: string[]
): Promise<AxiosResponse> => {
  return carriersAxios.put(
    `${CARRIERS_API_URL}/carriers/${carrierId}/services/${serviceId}/edit`,
    { name, description, status }
  );
};

const tryToDeleteCarrierService = (
  carrierId: string,
  serviceId: string,
  // data: any
): Promise<AxiosResponse> => {
  return carriersAxios.put(
    `${CARRIERS_API_URL}/carriers/${carrierId}/services/${serviceId}/delete`,
    // data
  );
};

export const CarriersService = {
  tryToFetchCarrierServices,
  tryToAddCarrierService,
  tryToEditCarrierService,
  tryToDeleteCarrierService,
};
