import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../../shared/button';
import LoginForm from './login-form';
import ForgotPassword from './modal/ForgotPasswordModal';


const Login = () => {
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState<boolean>(false)

    const onShowHideForgotPassword = () => {
        setShowForgotPasswordModal(!showForgotPasswordModal)
    }

    return (
        <div className='md:min-h-screen px-10 py-12'>
            <NavLink to='/'>
                {/* Logo here */}
                {/* <img className='max-w-[290px]' alt='for the earth' src='/logo/logo.svg' /> */}
            </NavLink>
            <div className='max-w-[330px] m-auto mt-[9%]'>
                <h1 className='font-dm_sansbold text-4xl'>Please fill your details</h1>
                <LoginForm />
                <div className='font-dm_sansregular text-center flex flex-col items-center mt-8'>
                    <Button
                        label={'Forgot/Reset your password?'}
                        className={'btn-primary-text !font-normal'}
                        onClickButton={onShowHideForgotPassword}
                    />
                    {/* <div className='my-5 min-w-[70%] border border-borderColor'></div>
                    <Button
                        label={'I don’t have an account.'}
                        className={'btn-primary-text !font-normal'}
                        onClickButton={() => navigate('/register')}
                    /> */}
                </div>
            </div>
            <ForgotPassword
                openForgotModal={showForgotPasswordModal}
                handleCloseForgotModal={onShowHideForgotPassword}
            />
        </div>
    )
}

export default Login;