import { AxiosResponse } from 'axios';
import { CARRIERS_API_URL } from '../../../../config';
import { PaginatedCarriers } from '../../../../interfaces/carriers/paginatedStore.type';
// import { PaginatedPartners } from '../../../../interfaces/partners/paginatedStore.type';
import carriersAxios from '../../../../utils/axios/carriers.axios';

// Paginated
const tryToFetchCarriers = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
): Promise<AxiosResponse<PaginatedCarriers>> => {
    const url = `${CARRIERS_API_URL}/carriers/?page=${pageNumber}&size=${pageSize}`
    return carriersAxios.get<PaginatedCarriers>(url);
};

const tryToFetchSingleCarrier = (carrierId: string): Promise<AxiosResponse> => {
    return carriersAxios.get(`${CARRIERS_API_URL}/carriers/${carrierId}`);
};

const tryToFetchAllCarriers = (): Promise<AxiosResponse> => {
    return carriersAxios.get(`${CARRIERS_API_URL}/carriers/all`);
};

const tryToAddCarrier = (data: any): Promise<AxiosResponse> => {
    return carriersAxios.post(`${CARRIERS_API_URL}/carriers`, data);
};

const tryToDisableCarrier = (carrierId: string): Promise<AxiosResponse> => {
    const payload = {
        'enabled': false
    }
    return carriersAxios.patch(`${CARRIERS_API_URL}/carriers/enable/${carrierId}`, payload);
};

const tryToDeleteCarrier = (carrierId: string): Promise<AxiosResponse> => {
    return carriersAxios.delete(`${CARRIERS_API_URL}/carriers/${carrierId}`);
};

const tryToEditCarrier = (carrierId: string, payload: any): Promise<AxiosResponse> => {
    return carriersAxios.patch(`${CARRIERS_API_URL}/carriers/${carrierId}`, payload);
};

const tryToAddCarrierField = (carrierId: string, data: any): Promise<AxiosResponse> => {
    return carriersAxios.put(`${CARRIERS_API_URL}/carriers/${carrierId}/add-field`, data);
};

const tryToEditCarrierField = (carrierId: string, fieldId: string, payload: any): Promise<AxiosResponse> => {
    return carriersAxios.put(`${CARRIERS_API_URL}/carriers/${carrierId}/update-field/${fieldId}`, payload);
};

const tryToAddCarrierLogo = (id: string, data: any): Promise<AxiosResponse> => {
    return carriersAxios.put(`${CARRIERS_API_URL}/carriers/uploadLogo/${id}`, data)
}
const tryToFetchCarrierField = (carrierId: string, fieldId: string): Promise<AxiosResponse> => {
    return carriersAxios.get(`${CARRIERS_API_URL}/carriers/${carrierId}/field/${fieldId}`);
};

const tryToDeleteCarrierLogo = (id: string): Promise<AxiosResponse> => {
    return carriersAxios.delete(`${CARRIERS_API_URL}/carriers/deleteLogo/${id}`)
}
const tryToDeleteCarrierField = (carrierId: string, fieldId: string): Promise<AxiosResponse> => {
    return carriersAxios.delete(`${CARRIERS_API_URL}/carriers/${carrierId}/delete-field/${fieldId}`);
};

export const CarriersService = {
    tryToFetchCarriers,
    tryToAddCarrier,
    tryToDisableCarrier,
    tryToFetchSingleCarrier,
    tryToAddCarrierField,
    tryToEditCarrierField,
    tryToFetchCarrierField,
    tryToDeleteCarrierField,
    tryToDeleteCarrierLogo,
    tryToEditCarrier,
    tryToAddCarrierLogo,
    tryToFetchAllCarriers,
    tryToDeleteCarrier
};
