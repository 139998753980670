import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    tryToDeleteCarrierField,
    tryToFetchSingleCarrier
} from '../../../store/brokers/admin/carriers/carriersSlice';
import { ICarrier } from '../../../interfaces/carriers/ICarrier';
import { IToast } from '../../../interfaces/components/IToast';
import CarrierFieldModal from './modals/CarrierFieldModal';
import CarrierFieldsTable from './table/CarrierFieldsTable';
import EmptyContainer from '../../../shared/empty-container';
import DeleteModal from '../../../shared/delete-modal';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const CarrierDetails = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [carrierData, setCarrierData] = useState<ICarrier>()
    const [showToast, setShowToast] = useState<IToast | null>()
    const [showFieldModal, setShowFieldModal] = useState<boolean>(false)
    const [fieldSelected, setFieldSelected] = useState<string>()
    const [showDeleteFieldModal, setShowDeleteFieldModal] = useState<boolean>(false)
    const state = useAppSelector((state) => state.carriers);
    const carriersIsLoading = useAppSelector((state) => state.carriers?.carrierIsLoading);

    useEffect(() => {
        if (state.carrierDetails) {
            setCarrierData(state.carrierDetails)
        }
    }, [state.carrierDetails]);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSingleCarrier(id));
        }
    }, []);

    const onToggleFieldModal = (id?: string) => {
        if (id) {
            setFieldSelected(id)
        }
        setShowFieldModal(!showFieldModal)
    }

    const onSaveFieldChanges = async (type: string, message: string) => {
        setShowToast({ type, message });
    }

    const onRequestToDeleteField = (id: string) => {
        setShowDeleteFieldModal(true)
        setFieldSelected(id)
    }

    const onCloseDeleteFieldModal = () => {
        setFieldSelected(undefined)
        setShowDeleteFieldModal(false)
    }

    const onDeleteCarrierField = async () => {
        try {
            if (fieldSelected) {
                const data = {
                    carrierId: id,
                    fieldId: fieldSelected
                }
                await dispatch(tryToDeleteCarrierField(data)).unwrap();
                setShowToast({ type: 'error', message: 'Field deleted successfully' });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        onCloseDeleteFieldModal()
    }

    const onGoBack = () => {
        navigate('/carriers');
    };

    return (
        <div>
            <div className='flex h-10'>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img alt='back' src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Manage Carriers</p>
                </div>
            </div>
            {!carriersIsLoading &&
                <>
                    <p className='mt-3 font-medium'>Display Name: {carrierData?.displayName}</p>
                    <p className='mt-3 font-medium'>Name: {carrierData?.name}</p>
                    <p className='mt-3 font-medium'>Description: {carrierData?.description}</p>
                </>
            }
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Add New Field'}
                    className={'btn-main'}
                    onClickButton={onToggleFieldModal}
                />
            </div>
            <div className='my-5 relative'>
                {((carrierData?.fields && carrierData?.fields?.length > 0) || carriersIsLoading) ?
                    <CarrierFieldsTable
                        rows={carrierData?.fields || []}
                        rowsLoading={carriersIsLoading}
                        onEditAssetField={onToggleFieldModal}
                        onDeleteAssetField={onRequestToDeleteField}
                    />
                    : <EmptyContainer text={'No field added yet'} showImage={true} />
                }
            </div>
            <DeleteModal
                openDeleteModal={showDeleteFieldModal}
                handleCloseDeleteModal={onCloseDeleteFieldModal}
                onApproveDeletion={onDeleteCarrierField}
            />
            <CarrierFieldModal
                openFieldsModal={showFieldModal}
                handleCloseFieldsModal={onToggleFieldModal}
                fieldItemId={fieldSelected}
                carrierId={id || ''}
                onSaveChanges={onSaveFieldChanges}
            />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div >
    )
}

export default CarrierDetails;