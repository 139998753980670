import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { ICarrier } from '../../../interfaces/carriers/ICarrier';
import { tryToFetchAllCarriers } from '../../../store/brokers/admin/carriers/carriersSlice';
import ManageCarrierFieldsModal from './modals/ManageCarrierFieldsModal';
import CarrierItemContainer from './loading/CarrierItemContainer';
import CarrierItem from './details/CarrierItem';
import Toast from '../../../shared/toast';


const BrokerCarriers = () => {
    const dispatch = useAppDispatch();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [carriers, setCarriers] = useState<Array<ICarrier>>()
    const [carrierSelected, setCarrierSelected] = useState<string>()
    const [showCarrierManageModal, setShowCarrierManageModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)

    const onGettingAllCarriers = async () => {
        const response = await dispatch(tryToFetchAllCarriers()).unwrap()
        setCarriers(response || [])
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        onGettingAllCarriers()
    }, []);

    const onToggleCarrierModal = (carrierId?: string) => {
        if (carrierId) {
            setCarrierSelected(carrierId)
        } else {
            setCarrierSelected(undefined)
        }
        setShowCarrierManageModal(!showCarrierManageModal)
    }

    const onSuccessfullyFinished = () => {
        setShowToast({ type: 'success', message: 'Successfully saved carrier data.' })
    }

    return (
        <div>
            <div className='mb-6'>
                <p className='page-title'>Carriers</p>
                <p>Click carrier card to edit or add data.</p>
            </div>
            <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
                {loading ?
                    <>
                        <CarrierItemContainer />
                        <CarrierItemContainer />
                        <CarrierItemContainer />
                    </>
                    : <>
                        {carriers && carriers?.length > 0 && carriers?.map((carrier: ICarrier, index: number) => {
                            return (
                                <CarrierItem
                                    key={index}
                                    carrierDetails={carrier}
                                    onClickCarrier={onToggleCarrierModal}
                                />
                            )
                        })}
                    </>
                }
            </div>
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
            <ManageCarrierFieldsModal
                openModal={showCarrierManageModal}
                handleCloseModal={onToggleCarrierModal}
                carrierId={carrierSelected}
                onSuccessfullyFinished={onSuccessfullyFinished}
            />
        </div>
    )
}

export default BrokerCarriers;