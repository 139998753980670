import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IShippingLabelsListState } from '../../../../interfaces/shipping-labels/IShippingLabels';
import { ShippingLabelsService } from './shippingLabelsApi';


const initialState: IShippingLabelsListState = {
    shippingLabelsAreLoading: true,
    toastSuccessRequest: false,
    shippingLabelsIsLoading: false,
    fieldIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchAllShipmentLabels = createAsyncThunk<any, { pageNumber: number, pageSize: number, data?: any }>(
    'shippingLabels/tryToFetchAllShipmentLabels', async ({ pageNumber, pageSize, data }) => {
        const result = await ShippingLabelsService.tryToFetchAllShipmentLabels(
            pageNumber,
            pageSize,
            data
        );
        return result?.data;
    })


export const shippingLabelsSlice = createSlice({
    name: 'shippingLabels',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchAllShipmentLabels
            .addCase(tryToFetchAllShipmentLabels.pending, (state) => {
                state.shippingLabelsAreLoading = true;
            })
            .addCase(tryToFetchAllShipmentLabels.rejected, (state) => {
                state.shippingLabelsAreLoading = false;
            })
            .addCase(tryToFetchAllShipmentLabels.fulfilled, (state, action) => {
                state.shippingLabelsAreLoading = false;
                state.shippingLabels = action.payload;
            });
    },
});
export default shippingLabelsSlice.reducer;