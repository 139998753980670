import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ASSETS_API_URL } from '../../config';
import { readFromStorage, removeFromStorage } from '../webStorage';
import userAxios from './user.axios';

const axiosConfig: AxiosRequestConfig = {
    baseURL: `${ASSETS_API_URL}` || 'http://localhost:3000/',
};
const assetsAxios = axios.create(axiosConfig);

const logout = () => {
    removeFromStorage('accessToken');
    removeFromStorage('refreshToken');
    window.location.reload();
};

// reqeust intercpetor
assetsAxios.interceptors.request.use((config) => {
    const token = readFromStorage('accessToken');
    // const isValid = validateJWT(token);
    if (token) {
        return {
            ...config,
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`,
            },
        };
    }
    return config;
});

// Add a response interceptor
assetsAxios.interceptors.response.use(
    (response) => {
        if (response?.data?.error === 'No permission!') logout();
        return response;
    },
    async (error) => {
        const err = { ...error };
        if (error.response.status === 401) {
            const err = { ...error };
            const originalRequest = err.config;
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    const token = readFromStorage('refreshToken');
                    const response = await userAxios.post('/dom/refresh-token', { token });
                    const accessToken = response.data.data.token;
                    localStorage.setItem('accessToken', accessToken);
                    axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken;
                    return assetsAxios(originalRequest);
                } catch (e) {
                    console.error(e);
                    logout();
                }
            } else {
                logout();
            }
        }
        return Promise.reject(err);
    },
);

export const assetsAxiosQuery =
    (): BaseQueryFn<{
        url: string;
        method: AxiosRequestConfig['method'];
        data?: AxiosRequestConfig['data'];
    }> =>
        async ({ url, method, data }) => {
            const baseUrl = axiosConfig.baseURL;
            try {
                const result = await assetsAxios({ url: baseUrl + url, method, data });
                return { data: result.data };
            } catch (axiosError) {
                const err = axiosError as AxiosError;
                return {
                    error: { status: err.response?.status, data: err.response?.data },
                };
            }
        };

export default assetsAxios;
